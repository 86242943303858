<style scoped lang="less">
.tag-box-panel {
  margin: 5px;
  display: inline;
  float: left;
}
.tag-item {
  color: white;
}
</style>

<template>
  <div class="tag-box-panel" v-if="dataSet">
    <div class="tag-item" v-for="(item, index) in tagList" :key="index">
      {{ getItemText(item) }}
    </div>
  </div>
</template>

<script>
import readTagValueFromDataSet from '../../../../assets/js/readTagValueFromDataSet'
export default {
  name: 'TagShowBox',
  components: {},
  props: {
    tagList: {
      type: Array,
      default: () => []
    },
    dataSet: {
      type: Object,
      default: null
    }
  },
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  created () { },
  mounted () {

  },
  methods: {
    getItemText (item) {
      return readTagValueFromDataSet(this.dataSet, item, true)
    }
  }
}
</script>
