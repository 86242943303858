<style scoped lang="less">
.measure-wrap {
  font-size: 15px;
  padding: 3px;

  .img-measured {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .image {
      .el-image {
        height: 180px;
        width: 180px;
      }
    }
    .upload {
      cursor: pointer;

      img {
        height: 35px;
        width: 35px;
      }
    }
  }
  .measure-annotation {
    margin: 3px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .annotation-wrap {
      max-width: 200px;
      word-wrap: break-word;
      white-space: normal;
      // color: #fdcb6e;
    }
  }
  .measure-info {
    border-left: 2px solid #409eff;
    padding: 2px 10px;

    .info-item {
      // text-align: center;
      // display: flex;
      // align-items: center;

      .desc {
        padding-left: 20px;
        cursor: pointer;
      }
      span {
        border-left: 2px solid #409eff;
        padding-left: 5px;
      }
    }
  }
}

/deep/ .custom-table tbody tr:hover > td {
  background-color: rgba(75, 101, 132, 1) !important;
}
</style>

<template>
  <div>
    <el-table
      :data="targets"
      width="100%"
      class="custom-table"
      :fit="true"
      height="100%"
      :key="itemKey"
      ref="table"
      @row-click="(row, column) => select(row, column)"
      @cell-mouse-enter="(row, column, cell) => (this.hoverRowUuid = row.id)"
      @cell-mouse-leave="(row) => (this.hoverRowUuid = undefined)"
    >
      <el-table-column width="30px" type="index"></el-table-column>
      <el-table-column label="测量指标信息">
        <template slot-scope="scope">
          <div class="measure-wrap">
            <div class="img-measured">
              <!-- <div class="image">
                    <el-image style="width: 160px; height: 160px;" :src="baseUrl + scope.row.imageUrl" alt="" :preview-src-list="[baseUrl + scope.row.imageUrl]"></el-image>
                  </div> -->
            </div>

            <div class="measure-info">
              <!-- <div v-for="item in processedTargets(scope.row.targets)" class="info-item">
                    <span>{{ item.groupName }} </span>
                    <div class="desc" v-for="ele in item.targetItems">  {{ ele.itemTitle }}: {{ ele.itemValue }} </div>
                  </div> -->

              <div class="info-item">
                <span>{{ scope.row.groupName }}</span>
                <!-- <div class="desc" v-for="ele in scope.row.items" @click="showImgViewer(ele)">{{ ele.lesionName }} - {{ ele.itemTitle }}: {{ ele.itemValue }} {{ ele.measureUnit }}</div> -->
                <div
                  class="desc"
                  v-for="(value, key) in processedByLesionName(scope.row.items)"
                  :key="key"
                >
                  <el-tag size="mini">{{ key }}</el-tag>
                  <div v-for="(ele, i) in value" :key="i" @click="showImgViewer(ele)">
                    {{ ele.itemTitle }}: {{ ele.itemValue }} {{ ele.measureUnit }}
                  </div>
                </div>
              </div>
              <div class="operation">
                <!-- <el-button
                          icon="el-icon-document-add"
                          @click.stop="imgUpload(scope.row)"
                          plain size="mini"
                          style="background-color: rgba(0, 0, 0, alpha);"
                          >添加至手术报告</el-button>

                        <el-button
                          icon="el-icon-delete"
                          @click.stop="deleteTarget(scope.row.id)"
                           plain size="mini"
                          style="background-color: rgba(0, 0, 0, alpha);"
                          >删除</el-button> -->
                <!-- <img src="../../../../assets/img/delete.png" alt="" @click.stop="deleteTarget(scope.row.id)" style="cursor: pointer;"> -->
              </div>
              <!-- <div v-for="item in measurementFormat(scope.row)">
                        <span>{{item.label }}: {{item.value}} {{ item.unit }}</span>
                    </div> -->
              <!-- <div><span>(S: {{ scope.row.dicom.seriesNumber }} | I: {{ scope.row.imageId.split('?')[1].slice(6) }}) </span></div> -->
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- <div slot="empty"></div> -->
    </el-table>
    <div>
      <el-image-viewer
        v-show="imgViewerVisible"
        :on-close="closeImgViewer"
        :url-list="currentImgList"
      />
    </div>
  </div>
</template>

<script>
import readMeasurement from '../../../../assets/js/readMeasurement'
export default {
  name: 'MeasurementsList',
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  props: {
    measurementsList: {
      type: Array,
      default: null
    },
    imgs: {
      type: Array,
      default: []
    },
    itemKey: {
      type: Number,
      default: 0
    },
    targets: {
      type: Array,
      default: []
    }
  },
  watch: {},
  data() {
    return {
      selectRowUuid: 0,
      hoverRowUuid: undefined,
      baseUrl: window.location.origin + '/api/v1/webconsole/downloadcenter/download',
      imgViewerVisible: false,
      currentImgList: [],
      currentPreview: {},
      firstRender: true
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // console.log('MeasurementsList--imgs:', this.imgs);
  },
  methods: {
    // 按照病变部位分组展示
    processedByLesionName(list) {
      let arr = {}
      for (let v of list) {
        if (arr[v.lesionName]) {
          arr[v.lesionName].push(v)
        } else {
          arr[v.lesionName] = [v]
        }
      }
      // console.log(arr);
      return arr
    },
    // 给指标分组展示
    processedTargets(data = []) {
      let list = []
      data.forEach((item) => {
        let matchedIndex = list.findIndex((ele) => ele.groupName == item.groupName)
        if (matchedIndex != -1) {
          list[matchedIndex].targetItems.push(item)
        } else {
          let obj = {
            groupName: item.groupName,
            targetItems: [item]
          }
          list.push(obj)
        }
      })
      // console.log(list);
      return list
    },
    // 点击显示图片
    showImgViewer(target) {
      // console.log('当前指标：',target);
      let imgUrl = target.imageUrl
      this.currentPreview = target
      // 如果是第一次打开，就动态渲染按钮
      if (this.firstRender) {
        let a = document.querySelector('.el-image-viewer__actions__inner')
        // 添加至手术报告
        // let addBtn = document.createElement('i')
        // addBtn.className = 'el-icon-document-add'
        // addBtn.addEventListener('click', () => {
        //   console.log(this.currentPreview);
        // })
        // a.appendChild(addBtn)

        // // 删除这个图片
        // let deleteBtn = document.createElement('i')
        // deleteBtn.className = 'el-icon-delete'
        // deleteBtn.addEventListener('click', () => {
        //   console.log(this.currentPreview);
        // })
        // a.appendChild(deleteBtn)

        let wrapper = document.querySelector('.el-image-viewer__wrapper')
        let add = document.createElement('div')

        add.textContent = '添加至手术报告'
        add.style.position = 'absolute'
        add.style.display = 'flex'
        add.style.justifyContent = 'center'
        add.style.alignItems = 'center'
        add.style.left = '35%'
        add.style.bottom = '1.875rem'
        add.style.height = '2.75rem'
        add.style.width = '10rem'
        add.style.color = '#fff'
        add.style.backgroundColor = '#606266'
        add.style.borderRadius = '2rem'
        add.style.cursor = 'pointer'
        add.style.transform = 'translateX(-50%)'
        add.child
        add.addEventListener('click', () => {
          this.imgUpload(this.currentPreview)
        })

        let dlt = document.createElement('div')

        dlt.textContent = '删除该测量记录'
        dlt.style.position = 'absolute'
        dlt.style.display = 'flex'
        dlt.style.justifyContent = 'center'
        dlt.style.alignItems = 'center'
        dlt.style.left = '65%'
        dlt.style.bottom = '1.875rem'
        dlt.style.height = '2.75rem'
        dlt.style.width = '10rem'
        dlt.style.color = '#fff'
        dlt.style.backgroundColor = '#606266'
        dlt.style.borderRadius = '2rem'
        dlt.style.cursor = 'pointer'
        dlt.style.transform = 'translateX(-50%)'
        dlt.addEventListener('click', () => {
          this.deleteTarget(this.currentPreview.imageId)
          this.closeImgViewer()
        })
        wrapper.appendChild(add)
        wrapper.appendChild(dlt)

        this.firstRender = false
      }

      this.currentImgList = [this.baseUrl + imgUrl]

      this.imgViewerVisible = true
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },
    // 点击关闭图片
    closeImgViewer() {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    },
    imgUpload(row) {
      this.$api
        .post(
          `/v1/webconsole/pacs/report/operation/add_measure/${row.imageId}/${this.$route.query.operationId}`
        )
        .then(({ data }) => {
          // console.log(data);
          if (data.status === 200) this.$message.success(data.msg)
          this.$emit('imgUpload')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 删除指标
    deleteTarget(id) {
      console.log(id)

      this.$api
        .post(`/v1/webconsole/pacs/measure/delete/${id}`)
        .then(({ data }) => {
          // console.log('删除测量指标数据', data);
          if (data.status === 200) this.$emit('deleteMeasurement')
        })
        .catch((err) => {
          console.log(err)
        })
    },

    measurementFormat(data) {
      // console.log('format',data);
      return readMeasurement(data)
    },
    select(row, column) {
      // console.log('row', row);
      this.selectRowUuid = row.uuid
      // 先注销，还有on操作没改
      // this.$bus.$emit('measurementClick', { filePath:row.dicom.filePath, data:row })
    }
  }
}
</script>
