export default function (data) {
    const toolName = data.toolName
    const toolType = data.toolType
    let arr = []
    // console.log(data);
    switch (toolName) {
        case 'Length':
            arr.push(initData('Length', data.length, data.unit))
            break;
        case 'Angle':
            arr.push(initData('rAngle', data.rAngle, data.unit))
            break;
        case 'EllipticalRoi':
            arr.push(initData('Area', data.cachedStats.area, 'mm²'))
            arr.push(initData('Max', data.cachedStats.max, ))
            break;
        case 'FreehandRoi':
            arr.push(initData('Area', data.area, 'mm²'))
            // arr.push(initData('Mean', data.meanStdDev.mean))
            // arr.push(initData('stdDev', data.meanStdDev.stdDev))
            break;
        case 'ArrowAnnotate':
            arr.push(initData('Text', data.text, ))
        default:
            break;
    }
    return arr
}

const initData = function(label, value, unit='') {
    return {'label': label, 'value': preProcess(value, label), 'unit': unit}
}

const preProcess = function(val, label) {
    if (!val) 
        return '';
    if (typeof val === 'number') 
        return val.toFixed(2)
    if (typeof val === 'string')
        if (label === 'Text') {
            return val
        } else {
            return parseFloat(val).toFixed(2)
        }
    return ''
}