<style lang="less" scoped>
.overflowXY {
  width: 100%;
  height: auto;
  //   overflow: auto;
  position: relative;
}
.overflowXY::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
  display: none;
}
.overflowXY::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: #fff;
}
.overflowXY::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #000;
}
.el-tree .el-tree-node {
  padding-left: 0;
}

.el-tree .is-checked {
  color: green;
}

// .study-wrap {
//   // box-sizing: border-box;
//   // padding: 10px;
// }

.title {
  height: 120px;
  background-color: #0a0c29;
  .title-head {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #348cfd;
    font-size: 18px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
  }
  .select-wrap {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    /deep/ .el-radio-group {
      border: 1px solid rgba(84, 109, 229, 0.8) !important;
      border-radius: 5px;
    }
    /deep/.el-radio-button {
      .el-radio-button__inner {
        //修改按钮样式
        background-color: #242424;
        color: #5acce6;
        border: 0 !important;
        &:hover {
          background-color: #5acce6;
          color: #000;
        }
      }
      .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        // 修改按钮激活样式
        background-color: #0944b3 !important;
      }
    }
  }
}

.desc_active {
  background-color: rgba(84, 109, 229, 0.3);
}
.desc {
  &:hover {
    background-color: rgba(60, 64, 198, 0.7);
  }
  border-top: 2px solid rgba(84, 109, 229, 0.8);
  // box-sizing: border-box;
  padding: 15px 25px;
  // width: 100%;
  border-radius: 5px;
  min-height: 80px;
  cursor: pointer;
  .desc-top {
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    // &:first-child {
    //     color: #fff;
    // }
    // &:last-child {
    //     color: rgba(75, 207, 250,1.0);
    // }
    margin-bottom: 10px;
    .desc-top-right {
      color: #8fcdf4;
      display: flex;
      align-items: center;
      width: 50px;
      justify-content: space-between;
    }
  }
  .desc-track {
    margin: 5px 0;
    background-color: rgba(60, 64, 198, 0.7);
    height: 26px;
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 170px;
    border-radius: 5px;
    padding: 2px;
    img {
      height: 22px;
      width: 22px;
      margin: 0 5px;
    }
    color: #fff;
  }
  .desc-body {
    font-size: 27px;
    color: #8fcdf4;
    &:last-child {
      font-size: 22px;
    }
  }
}

.track {
  background-color: rgba(60, 64, 198, 0.7);
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
  }
  color: #fff;
  box-shadow: 0 -1px 1px rgba(84, 109, 229, 0.8);
}

.study {
  padding-top: 20px;
  overflow-y: auto;
  height: calc(88.5vh - 235px);
  .study-item {
    height: 280px;
    width: 100%;
    display: flex;
    .item-left {
      width: 50px;
      display: flex;
      justify-content: center;
      padding-top: 20px;
      .circle {
        height: 20px;
        width: 20px;
        border: 2px dashed #8fcdf4;
        border-radius: 50%;
      }
      img {
        height: 24px;
        width: 24px;
      }
    }
    .item-right {
      flex: 1;
      padding: 20px;
      .thumb {
        border: 1px solid rgba(60, 64, 198, 0.6);
        border-radius: 7px;
        cursor: pointer;
        &:hover {
          border: 2px solid #8fcdf4 !important;
        }
      }
      .thumb-desc {
        .desc-top {
          display: flex;
          align-items: center;
          margin: 10px 4px;
          .desc-top-wrap {
            img {
              height: 16px;
              width: 16px;
              margin-right: 10px;
            }
            width: 60px;
            color: #8fcdf4;
          }
        }
        .desc-body {
          color: #fff;
        }
      }
    }
  }
  .sr-item {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    padding: 10px 10px;
    // height: 100px;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 10px;
    margin-bottom: 20px;
    &:hover {
      border: 1px solid #8fcdf4;
    }
    .item-top {
      display: flex;
      align-items: center;

      .item-top-left {
        margin: 0 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        img {
          height: 40px;
          width: 35px;
        }
      }
      .item-top-mid {
        margin: 0 15px;
        border-radius: 5px;
        font-size: 19px;
        height: 32px;
        width: 57px;
        background-color: #1b79d7;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item-top-right {
        color: #8fcdf4;
        font-size: 17px;
      }
    }
    .item-body {
      color: #fff;
      font-size: 18px;
      margin: 3px 10px;
    }
  }
}
.sr_active {
  border: 3px solid #8fcdf4 !important;
}
.thumb_active {
  border: 3px solid #8fcdf4 !important;
}

.study::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.study::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  margin: 2px;
  background: rgba(104, 109, 224, 0.5);
}
.study::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
}
</style>

<template>
  <div class="overflowXY">
    <!-- <div style="flex: 1; display: flex"> -->
    <!-- <el-tree :data="list"
               :props="defaultProps"
               :default-expand-all="true"
               node-key="filePath"
               accordion
               ref="tree"
               @node-click="handleNodeClick">
      </el-tree> -->
    <div class="title" v-if="!this.$route.query.studyUUID">
      <div class="title-head">
        <span>Studies</span>
      </div>
      <div class="select-wrap">
        <el-radio-group text-color="#5ACCE6" fill="#0944B3" v-model="timeRange" size="small">
          <el-radio-button label="近一周"></el-radio-button>
          <el-radio-button label="近一月"></el-radio-button>
          <el-radio-button label="全部"></el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div v-for="(item, index) in list" class="study-wrap" :key="index">
      <div class="desc" :class="item.visible ? 'desc_active' : ''" @click="studyClick(index)">
        <div class="desc-top">
          <span>{{ item.studyTime }}</span>
          <div class="desc-top-right" v-if="item.children">
            <img src="../../../../assets/img/layer.png" alt="" />
            <span>{{ item.children?.length }}</span>
          </div>
        </div>

        <div class="desc-body">
          <!-- <div v-if="item.modality">{{ item.modality.join('\/') }}</div> -->
          <div>{{ item.studyDescription }}</div>
        </div>
        <div class="desc-track" v-if="item.isTracked && !item.visible">
          <img src="../../../../assets/img/tick_circle.png" alt="" />
          <span>
            {{ item.children && item.children.filter((item) => item.isTracked)?.length }}
            组序列被标注
          </span>
        </div>
      </div>
      <div class="track" v-if="item.isTracked && item.visible">
        <img src="../../../../assets/img/tick_circle.png" alt="" />
        <span>
          {{ item.children && item.children.filter((item) => item.isTracked)?.length }} 组序列被标注
        </span>
      </div>
      <div class="study" v-show="item.visible">
        <div class="study-item" v-for="(ele, i) in item.children" :key="i">
          <div class="item-left">
            <div class="circle" v-if="!ele.isTracked"></div>
            <img src="../../../../assets/img/tick_circle.png" v-else alt="" />
          </div>
          <div class="item-right">
            <div
              class="thumb"
              :class="ele.selected ? 'thumb_active' : ''"
              @click="selectThumb(index, i)"
            >
              <dicom-item
                :series="ele"
                @sourcePathArrived="sourcePathArrived($event, index, i)"
              ></dicom-item>
            </div>
            <div class="thumb-desc">
              <div class="desc-top">
                <div class="desc-top-wrap">
                  <img src="../../../../assets/img/label.png" alt="" />
                  <span>{{ ele.seriesNumber }}</span>
                </div>
                <div class="desc-top-wrap">
                  <img src="../../../../assets/img/lft.png" alt="" />
                  <span>{{ ele.imageIds?.length }}</span>
                </div>
              </div>
              <div class="desc-body">
                <span>{{ ele.modality }} {{ ele.seriesDescription }}</span>
                <div>{{ ele.examinePart }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="sr-item"
          v-for="(ele, i) in item.SRList"
          :class="ele.selected ? 'sr_active' : ''"
          @click="srClick(index, i)"
          :key="i"
        >
          <div class="item-top">
            <div class="item-top-left"><img src="../../../../assets/img/list.png" alt="" /></div>
            <div class="item-top-mid">
              <span>SR</span>
            </div>
            <div class="item-top-right">
              <span>{{ ele.studyData }}</span>
            </div>
          </div>
          <div class="item-body">
            <span>{{ ele.modality }} SR</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div style="position: absolute; left: 0; bottom: 25px; height: 1em; color: #fff">
      <span>{{ state }}</span>calc(100% - 1em)
    </div> -->
  <!-- </div> -->
</template>

<script>
import DicomItem from './DicomItem.vue'
import { deepClone } from '../../../../plugins/clone'
import { SERVER_HOST } from '../../../../utils/globalVariable'
import { todayDate, getRecentWeekDate, getRecentMonthDate } from '../../../../utils/index'
export default {
  name: '',
  components: {
    DicomItem
  },
  props: {
    message: {
      type: String,
      default: ''
    },
    selectedDicom: {
      type: [Object, File],
      default: () => ({})
    },
    dicomViewerNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 本地dicom调试用
      // list: [{ children: [], type: 'local', studyDescription: '本地DICOM' }],
      list: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      selectedNode: null,
      state: '获取影像数据中...',
      patientsID: '',
      dicomHasMeasurements: [],
      selectedThumb: null,
      structuredDicomList: [],
      timeRange: '近一周',
      studyList: []
    }
  },
  computed: {
    timeRangeToString() {
      let startTime
      let timeRange = this.timeRange
      if (timeRange === '近一周') {
        startTime = getRecentWeekDate(new Date())
      } else if (timeRange === '近一月') {
        startTime = getRecentMonthDate(new Date())
      } else {
        startTime = null
      }
      return startTime
    }
  },
  watch: {
    message: {
      handler(newData) {
        if (newData) {
          this.patientsID = newData
        }
      }
    },
    selectedDicom: {
      handler(newVal) {
        if (newVal) {
          this.selectThumbByFileName(newVal)
        }
      }
    },
    // dicomViewerNum: {
    //   handler(newVal, oldVal) {
    //     if (newVal > 1) {
    //     }
    //   }
    // },
    timeRangeToString: {
      handler() {
        this.getPacsList()
      }
      // immediate: true
    }
  },
  created() {
    // this.getPacsList()
  },
  mounted() {
    this.getPacsList()
    this.$bus.$on('finishedAnnotation', this.checkDicomTrack)
    // this.$bus.$on('measurementClick', this.selectDicomByActivateMeasurement)
  },
  beforeDestroy() {
    this.$bus.$off('finishedAnnotation')
  },
  methods: {
    // 获取到dicom文件下载地址
    sourcePathArrived(e, index, i) {
      // console.log(e, index, i);
      this.list[index].children[i].sourcePath = e
      this.list[index].children[i].filePath = window.location.origin + e
    },
    // 多视图模式时 添加编号
    addNumber() {},
    // 获取列表
    getPacsList() {
      let formData = new FormData()
      // formData.append('operationId', this.$route.query.operationId)
      if (this.timeRangeToString) {
        formData.append('startTime', this.timeRangeToString)
      }
      let operationId = this.$route.query.operationId
      let patientsID = this.$route.query.patientsID
      let studyUUID = this.$route.query.studyUUID
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if (operationId) {
        this.$api
          .post(`/v1/webconsole/pacs/studies/${operationId}`, formData)
          .then(({ data }) => {
            // console.log(data)
            if (data.status === 200) {
              // 本地测试
              // let local = this.list[0]
              for (let i of data.data) {
                i.visible = false
              }
              this.list = data.data
            }
            loading.close()
          })
          .catch((err) => {
            console.log(err)
            loading.close()
          })
      } else if (patientsID) {
        this.$api
          .post(`/v1/webconsole/pacs/patient/studies/${patientsID}`, formData)
          .then(({ data }) => {
            // console.log(data)
            if (data.status === 200) {
              // 本地测试
              // let local = this.list[0]
              for (let i of data.data) {
                i.visible = false
              }
              this.list = data.data
              if (studyUUID) {
                let idx = this.list.findIndex((item) => item.studyInstanceUid === studyUUID)
                if (idx !== -1) {
                  this.studyClick(idx).then(() => {
                    this.selectThumb(idx, 0)
                  })
                }
              }
            }
            loading.close()
          })
          .catch((err) => {
            console.log(err)
            loading.close()
          })
      }
    },
    selectDicomByActivateMeasurement(val) {
      // const filePath = val.dicom.filePath
      this.list.forEach((item) => {
        item.children.forEach((ele, i, arr1) => {
          // console.log(ele)
          arr1[i].selected = false
        })
      })
    },
    // 查看是否更新 track 图标
    checkDicomTrack(msg) {
      // console.log(msg)
      if (msg.type === 'add') {
        this.list.forEach((item) => {
          item.children.forEach((ele, i, arr) => {
            if (ele.filePath === msg.filePath) {
              arr[i].isTracked = true
            }
          })
        })
      } else if (msg.type === 'clear') {
        this.list.forEach((item) => {
          item.children.forEach((ele, i, arr) => {
            if (ele.filePath === msg.filePath) {
              arr[i].isTracked = false
            }
          })
        })
      }
    },
    srClick(index, i) {
      // 结构化数据
      this.list.forEach((item) => {
        item.SRList.forEach((ele, i, arr) => {
          arr[i].selected = false
        })
      })
      // 缩略图
      this.list.forEach((item) => {
        item.children.forEach((ele, i, arr1) => {
          arr1[i].selected = false
        })
      })
      this.list[index].SRList[i].selected = true
    },
    selectThumbByFileName(dicom) {
      // console.log('selectThumbByFileName', dicom);
      this.list.forEach((item, index, arr) => {
        item.children?.forEach((ele, i, arr1) => {
          if (ele.seriesInstanceUid === dicom.seriesInstanceUid) {
            arr1[i].selected = true
          } else {
            arr1[i].selected = false
          }
        })
      })
      // this.$emit('dicomClick', dicom)
    },
    // 选中缩略图
    selectThumb(index, i) {
      // console.log(index, i)
      // 缩略图
      this.list.forEach((item) => {
        item.children?.forEach((ele, i, arr1) => {
          arr1[i].selected = false
        })
      })

      this.list[index].children[i].selected = true
      this.selectedThumb = this.list[index].children[i]
      this.$emit('seriesClick', this.list[index].children[i])
    },
    // 点击展开study
    studyClick(index) {
      // console.log('study-index:',index);
      let study = this.list[index]

      // 本地dicom调试用
      // if (study.type && study.type === 'local') {
      //   this.$set(this.list[index], 'visible', !this.list[index].visible)
      //   return
      // }

      this.$set(this.list[index], 'visible', !this.list[index].visible)
      // 上传当前studyInstanceUid
      this.$emit('studyClick', { studyInstanceUid: study.studyInstanceUid, visible: study.visible })
      return new Promise((resolve, reject) => {
        if (!study.children && study.visible) {
          let operationId = this.$route.query.operationId
          let patientsID = this.$route.query.patientsID
          let loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          if (operationId) {
            this.$api
              .post(
                `/v1/webconsole/pacs/series/${operationId}/${study.studyInstanceUid}`,
                JSON.stringify(study.fileIds)
              )
              .then(({ data }) => {
                // console.log(data)
                let studyDeepCopy = JSON.parse(JSON.stringify(study))
                studyDeepCopy.children = data.data
                studyDeepCopy.isTracked = false
                studyDeepCopy.modality = []
                // // console.log("dicomHasMeasurement: ", this.dicomHasMeasurements);
                for (let item of studyDeepCopy.children) {
                  studyDeepCopy.modality.push(item.modality)
                  item.selected = false
                  // item.filePath = process.env.VUE_APP_BASE + item.sourcePath
                  item.filePath = item.sourcePath
                  // item.filePath = '/dicom/dicom/570169/XA/20200504/1.3.46.670589.28.961615300847.202005040443375768712211521.dcm'
                  // item.isValid = true
                  // item.isEnd = true
                  item.isLoaded = false
                }
                studyDeepCopy.modality = [...new Set(studyDeepCopy.modality)]
                // 深拷贝赋值
                let listDeepCopy = JSON.parse(JSON.stringify(this.list))
                listDeepCopy[index] = studyDeepCopy
                this.list = null
                this.list = listDeepCopy
                loading.close()
                resolve()
              })
              .catch((err) => {
                console.log(err)
                loading.close()
                reject(err)
              })
          } else if (patientsID) {
            this.$api
              .post(
                `/v1/webconsole/pacs/patient/series/${patientsID}/${study.studyInstanceUid}`,
                JSON.stringify(study.fileIds)
              )
              .then(({ data }) => {
                // console.log(data)
                let studyDeepCopy = JSON.parse(JSON.stringify(study))
                studyDeepCopy.children = data.data
                studyDeepCopy.isTracked = false
                studyDeepCopy.modality = []
                // // console.log("dicomHasMeasurement: ", this.dicomHasMeasurements);
                for (let item of studyDeepCopy.children) {
                  studyDeepCopy.modality.push(item.modality)
                  item.selected = false
                  // item.filePath = process.env.VUE_APP_BASE + item.sourcePath
                  item.filePath = item.sourcePath
                  // item.filePath = '/dicom/dicom/570169/XA/20200504/1.3.46.670589.28.961615300847.202005040443375768712211521.dcm'
                  // item.isValid = true
                  // item.isEnd = true
                  item.isLoaded = false
                }
                studyDeepCopy.modality = [...new Set(studyDeepCopy.modality)]
                // 深拷贝赋值
                let listDeepCopy = JSON.parse(JSON.stringify(this.list))
                listDeepCopy[index] = studyDeepCopy
                this.list = null
                this.list = listDeepCopy
                loading.close()
                resolve()
              })
              .catch((err) => {
                console.log(err)
                loading.close()
                reject(err)
              })
          }
        }
      })
    },
    handleNodeClick(data, node) {
      if (data.isEnd) {
        if (node.selectedNode != node.parent.data) {
          this.selectedNode = node.parent.data
          this.$emit('reload', node.parent.data.children)
        }
        this.$emit('change', data)
      } else if (data.isValid && this.selectedNode != data) {
        this.selectedNode = data
        this.$emit('reload', data.children)
      }
    },
    getDicomList() {
      this.$api.get('/getDicom', { patientId: this.patientsID }).then((res) => {
        if (res.data.status == 200) {
          // console.log(res.data);
          let data = res.data.data?.data || []
          data.forEach((item, i, arr) => {
            arr[i].isTracked = false
            for (let ele of this.dicomHasMeasurements) {
              if (ele === item.filePath) {
                arr[i].isTracked = true
                // this.structuredDicomList.push(deepClone(item))
              }
            }
          })
          let list = []
          for (let dcm of data) {
            let arr = list
            let node
            let index
            dcm.studyData = dcm.studyData.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
            if (-1 == arr.findIndex((e) => e.studyData === dcm.studyData)) {
              dcm.isValid = true
              dcm.isEnd = true
              dcm.selected = false
              // SRList
              // node = { studyData: dcm.studyData, modality: [dcm.modality], children: [dcm], visible: false, SRList: [Object.assign({}, dcm)] }
              node = {
                studyData: dcm.studyData,
                modality: [dcm.modality],
                children: [dcm],
                visible: false
              }
              arr.push(node)
            } else {
              let index = arr.findIndex((e) => e.studyData === dcm.studyData)
              if (!arr[index].modality.includes(dcm.modality)) {
                arr[index].modality.push(dcm.modality)
              }
              dcm.isValid = true
              dcm.isEnd = true
              dcm.selected = false
              arr[index].children.push(dcm)
            }
          }
          list.forEach((item, i) => {
            list[i].children.sort((a, b) => a.seriesNumber - b.seriesNumber)
          })
          this.list = list
          //   console.log(list);
          this.state = '加载完成...'
        }
      })
    }
  }
}
</script>
