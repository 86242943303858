<template>
  <div class="progress-wrapper" :style="wrapStyle">
    <div class="play-btn" @click.stop="clickPlay">
      <img v-if="!playStatus" src="../../../../assets/img/btnPlay.Image.png" alt="" />
      <img v-else src="../../../../assets/img/btnPause.Image.png" alt="" />
    </div>
    <div class="progress" @click.stop="clickStop" :style="pBarStyle">
      <div class="left" :style="leftStyle">
        <div class="ball" :style="ballStyle"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    leftBg: String,
    bgc: String,
    ballBgc: String,
    height: String,
    width: String,
    percent: {
      type: Number,
      default: 10
    }
  },
  data: function () {
    return {
      wrapStyle: {
        width: this.width
      },
      pBarStyle: {
        backgroundColor: this.bgc,
        height: this.height
      },
      leftStyle: {
        width: this.percent + '%',
        background: this.leftBg,
        height: this.height
      },
      ballStyle: {
        backgroundColor: this.ballBgc,
        height: this.height,
        width: this.height,
        borderRadius: parseInt(this.height) / 2 + 'px',
        right: -parseInt(this.height) / 2 + 'px'
      },
      playStatus: 0
    }
  },
  computed: {
    progressElement() {
      return this.$el.getElementsByClassName('progress')[0]
    }
  },
  mounted() {
    this.$bus.$on('dicomLoaded', () => {
      this.playStatus = 0
    })
  },
  beforeDestroy() {
    this.$bus.$off('dicomLoaded')
  },
  methods: {
    clickStop(e) {
      let decimal = e.offsetX / this.progressElement.clientWidth
      let percent = Math.round(decimal * 100)
      this.leftStyle.width = percent + '%'
      this.$emit('pbar-seek', percent)
    },
    clickPlay() {
      this.playStatus = !this.playStatus
      if (this.playStatus) this.$emit('play', 1)
      else this.$emit('play', 0)
    }
  },
  watch: {
    percent(cur, old) {
      this.leftStyle.width = cur + '%'
    }
  }
}
</script>

<style lang="less" scoped>
// .progress-wrapper{
//     /*width:100%;*/
// }
.play-btn {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, alpha);
  border: 1px solid #fff;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
  }
}
.progress {
  width: 100%;
  height: 20px;
  background-color: #ccc;
  overflow: hidden;
  cursor: pointer;
}
.left {
  height: 100%;
  /* width: 30%; */
  background-color: greenyellow;
  position: relative;
}
.ball {
  height: 100%;
  width: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background-color: red;
  position: absolute;
  right: -10px;
}
</style>
