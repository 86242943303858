<style scoped lang="less">
.footer-panel {
  box-sizing: border-box;
  height: 25px;
  width: 100%;
  background: #242424;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #a4c2ea;
  font-size: 12px;
  color: #ffffff;
  border-top: 1px solid #a4c2ea;
}
.div-block {
  border-right: 1px solid #91afd8;
}
</style>

<template>
  <div class="footer-panel">
    <div v-if="dicomViewer && dicomViewer.dicom" style="width: 100%">
      <el-col :span="2">
        <div class="div-block" style="margin-left: 10px">
          Plane: {{ dicomViewer.currentFrameIndex }}/{{
            dicomViewer.framesCount
          }}
        </div>
      </el-col>
      <el-col :span="2">
        <div class="div-block" style="margin-left: 10px">
          Frame rate: {{ dicomViewer.frameRate }}
        </div>
      </el-col>
      <el-col :span="2">
        <div
          class="div-block"
          style="text-align: center"
          v-if="dicomViewer.image"
        >
          {{ dicomViewer.image.rows }} x {{ dicomViewer.image.columns }}
        </div>
      </el-col>
      <el-col :span="14">
        <div class="div-block">&emsp;</div>
      </el-col>
      <el-col :span="4"></el-col>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterBar',
  model: {
    prop: 'dicomViewer'
  },
  components: {},
  props: {
    dicomViewer: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  created () { },
  mounted () { },
  methods: {}
}
</script>
