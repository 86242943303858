<style scoped lang="less">
.dicom-viewer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  display: inline-block;
  border-style: solid;
  border-color: #242424;
  border: 3px solid white;
  background: #242424;
}
.selected {
  border: 3px solid greenyellow;
}
.dicom-image {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
}
.tag-box-left-top {
  position: absolute;
  left: 0;
  top: 0;
}
.tag-box-right-top {
  position: absolute;
  right: 0;
  top: 0;
}
.tag-box-right-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
}
.tag-box-left-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}
.progress-bar-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.menu {
  // width: 200px;
  font-size: 14px;
  font-family: '微软雅黑';
  border: 1px solid #ccc;
  color: #000;
  z-index: 9999;
  left: 0;
  top: 0;
  position: absolute;
  background: #f2f2f2;
  // padding: 5px 20px;
  // border-radius: 5px;
}

.menu ul {
  margin: 0px;
  padding: 0px;
  text-align: center;
  list-style-type: none;
}

.menu ul li {
  padding: 10px 20px;
  font-size: 12px;
}

.menu ul li:hover {
  background: #c1dcff;
}

.menu ul li a:link {
  color: #000;
  text-decoration: none;
}

.el-input {
  width: 250px;
}

.el-cascader {
  width: 250px;
}
</style>

<template>
  <div
    class="dicom-viewer"
    id="dicom-viewer"
    oncontextmenu="return false"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    :class="{ selected: isSelected }"
    @click.stop="_click($event)"
  >
    <div class="dicom-image" ref="DicomImage"></div>
    <div
      class="menu"
      ref="ContentMenu"
      :style="positionStyleText"
      v-show="isShowContentMenu"
      v-clickoutside="handleOutside"
    >
      <ul>
        <li @click="deleteAnnotation">删除此标注</li>
        <!-- <li v-for="(item, index) in menuItem" :key="index" v-debounce="addImage">
          添加到
          <span style="color: rgb(72, 128, 192)">{{ item }}</span>
          ——报告
        </li> -->
      </ul>
    </div>
    <tag-show-box
      class="tag-box-left-top"
      :dataSet="image && image.data"
      :tagList="tagList.lt"
    ></tag-show-box>
    <tag-show-box
      class="tag-box-right-top"
      :dataSet="image && image.data"
      :tagList="tagList.rt"
    ></tag-show-box>
    <tag-show-box
      class="tag-box-right-bottom"
      :dataSet="image && image.data"
      :tagList="tagList.rb"
    ></tag-show-box>
    <tag-show-box
      class="tag-box-left-bottom"
      :dataSet="image && image.data"
      :tagList="tagList.lb"
    ></tag-show-box>
    <progress-bar
      class="progress-bar-bottom"
      width="100%"
      leftBg="white"
      bgc="#ccc"
      ballBgc="#242424"
      :percent="percent"
      @pbar-seek="seek"
      @play="playClick"
    ></progress-bar>

    <!-- 完成测量对话框 -->
    <el-dialog title="测量录入" :visible.sync="dialogFormVisible">
      <el-form :model="measurementForm" ref="measurementForm">
        <el-form-item label="测量指标" label-width="80px">
          <el-cascader
            ref="cascader"
            v-model="measurementForm.group"
            :options="targetGroup"
            :props="{ expandTrigger: 'hover' }"
            @change="cascaderChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="测量数据" label-width="80px">
          <el-radio-group v-model="measurementForm.value">
            <span v-for="item in currentMeasurementData.data" style="margin: 5px">
              <el-radio :label="item.value">
                {{ item.key }} : {{ item.value }} {{ item.unit ? item.unit : '' }}
              </el-radio>
            </span>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelMeasurementForm">取 消</el-button>
        <el-button type="primary" @click="saveMeasurementForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { PopupManager } from 'element-ui/lib/utils/popup'
import TagShowBox from './TagShowBox'
import { getPosition } from '../../../../assets/js/mouseEvent'
import { PACSPrint } from '../../../../assets/js/SelfEntity'
import { getQueryVariable } from '../../../../assets/js/urlUtils'
import { createNewImage } from '../../../../assets/js/imageUtils'
import ProgressBar from './ProgressBar.vue'
export default {
  name: 'DicomViewer',
  components: {
    TagShowBox,
    ProgressBar
  },
  props: {
    tagList: {
      type: Object,
      default: () => {
        return {
          lt: [],
          rt: [],
          rb: [],
          lb: []
        }
      }
    },
    measurementsList: {
      type: Array,
      default: () => []
    },
    isRefresh: {
      type: String,
      default: ''
    },
    dicomViewerNum: {
      type: Number,
      default: 0
    },
    // 内置指标分组
    targetGroup: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      isSelected: false,
      dicom: null,
      image: null,
      elements: [],
      // frameRate: 10,
      timerId: null,
      status: 'stop', //play || pause || stop
      frameIndex: -1,
      order: 'positive', //positive || reverse,
      isShowContentMenu: false,
      positionStyleText: null,
      menuItem: [],
      percent: 0,
      // 当前标注
      annotations: null,
      lastClickCoords: { x: 0, y: 0 },
      // measurementsList: [],
      isMeasurementCompleted: true,
      isNewMeasurement: false,
      allowTracks: false,
      firstSelected: true,
      // 测量录入
      dialogFormVisible: false,
      // 测量标注缓存表单
      measurementForm: {
        group: '',
        toolType: '',
        value: '',
        annotationUuid: '',
        imageId: ''
      },
      currentMeasurementData: {},
      cacheSeriesMeasurements: []
      // cacheSeriesMeasurements: [
      //   // {
      //   //   imageId: '',
      //   //   itemId: '', // 指标id
      //   //   itemValue: '', // 测量值
      //   //   itemName: '' // 指标名
      //   // }
      // ]
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient,
    }),
    frameRate: function () {
      return this.$store.state.num
    },
    startFrame: function () {
      return this.$store.state.start_num
    },
    finishFrame: function () {
      return this.$store.state.finish_num
    },
    framesCount: function () {
      return this.dicom?.imageIds?.length || 0
    },
    currentFrameIndex: {
      get: function () {
        return this.frameIndex + 1
      },
      set: function (val) {
        this.frameIndex = val - 1
        this.displayImage(this.frameIndex)
      }
    }
  },
  watch: {
    frameIndex: {
      handler(newVal) {
        if (newVal >= 0) {
          this.percent = Math.round(((newVal + 1) / this.framesCount) * 100)
        }
      }
    },
    dicomViewerNum: {
      handler(newVal, oldVal) {
        // console.log('newVal:',newVal, 'oldVal:', oldVal );
        if (oldVal > newVal) {
          // console.log('再次');
          this.$bus.$on('measurementClick', (msg) => {
            if (!!this.dicom && msg.filePath === this.dicom.filePath) {
              this.handleMeasurementActivate(null, 2, msg.data)
            }
          })
        }
      }
    },
    isRefresh: {
      handler(val) {
        if (val === 'isRefresh') {
          this.getDicomContext()
        }
      }
    },
    dialogFormVisible: {
      handler(val) {
        if (val === false) {
          // 初始化
          this.measurementForm = {
            group: '',
            toolType: '',
            value: '',
            annotationUuid: '',
            imageId: ''
          }
        }
      }
    }
  },
  created() {},
  mounted() {
    let element = this.$refs.DicomImage
    this.$cornerstone.enable(element)
    element.oncontextmenu = this.showContentMenu.bind(this)
    this.elements.push(element)
    let operationId = getQueryVariable('operationId')
    let patientsID = getQueryVariable('patientsID')
    if (operationId) {
      this.$store.dispatch('setCurrentPatientAct', operationId)
    } else if (patientsID) {
      this.$store.dispatch('setCurrentPatientActByPatientsID', patientsID)
    }
    // var id=location.hash.substr(location.hash.indexOf("=") + 1);
    // this.menuItem =  JSON.parse(localStorage.getItem(id))
    // this.getDicomContext()
    setInterval(() => {
      this.$store.commit('setFrameNum', this.framesCount)
    })

    let _this = this

    // document.getElementById('dicom-viewer').addEventListener('click', function (e) {
    //   // console.log('e', e);
    //   _this._click()
    //   // e.stopImmediatePropagation()

    // })

    // 监听测量新增
    const addEvtType = this.$cornerstoneTools.EVENTS.MEASUREMENT_ADDED
    element.addEventListener(addEvtType, function (evt) {
      const eventData = evt.detail
      // console.log('add', evt);
      _this.handleMeasurementAdd(eventData)
    })

    // 监听测量修改
    // const modifyEvtType = this.$cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED;
    // element.addEventListener(modifyEvtType, function (evt) {
    //   const eventData = evt.detail;
    //   console.log('modify', evt);
    // });

    // 监听测量列表选中
    this.$bus.$on('measurementClick', (msg) => {
      // console.log('msg', msg, 'this.dicom', this.dicom);
      if (!!this.dicom && msg.filePath === this.dicom.filePath) {
        this.handleMeasurementActivate(null, 2, msg.data)
      }
    })

    // 监听测量完成事件
    const completedEvtType = this.$cornerstoneTools.EVENTS.MEASUREMENT_COMPLETED
    element.addEventListener(completedEvtType, function (evt) {
      const eventData = evt.detail
      _this.handleMeasurementComplete(eventData)
    })

    // 监听点击标注事件
    const activateEvtType = this.$cornerstoneTools.EVENTS.MOUSE_CLICK
    element.addEventListener(activateEvtType, function (evt) {
      // 取消事件冒泡
      // evt.cancelBubble = true
      const eventData = evt.detail
      // console.log(eventData);
      // 左键
      if (eventData.event.button === 0) {
        _this.handleMeasurementActivate(eventData)
      }
      // 右键
      if (eventData.event.button === 2) {
        // _this.handleMeasurementActivate(eventData)
        // 这是个坑，要用canvas坐标，不能用image坐标
        _this.lastClickCoords = eventData.currentPoints.canvas
      }
    })

    // 监听鼠标滚轮播放
    const wheelEvtType = this.$cornerstoneTools.EVENTS.MOUSE_WHEEL
    element.addEventListener(wheelEvtType, function (evt) {
      const eventData = evt.detail
      // console.log('eventData scroll');
      if (_this.isMeasurementCompleted) {
        _this.handleScrolle(eventData)
      }
    })
  },
  beforeDestroy() {
    // console.log('beforeDestroy', this.dicom);
    // if(this.$parent.)
    this.$bus.$off('measurementClick')
  },
  methods: {
    // 级联选择器选择触发，自动选择与指标匹配的测量数据
    cascaderChange(e) {
      let node = this.$refs.cascader.getCheckedNodes()
      let measure = node[0].data.measure
      let pattern = measure.toLowerCase()
      let matchList = this.currentMeasurementData.data.filter(
        (item) => item.key.toLowerCase() == pattern
      )
      this.measurementForm.annotationUuid = this.currentMeasurementData.uuid
      this.measurementForm.imageId = this.currentMeasurementData.imageId
      // console.log(matchList)
      // 判断是否命中指标指定的测量类型
      if (matchList.length > 0) {
        this.measurementForm.value = matchList[0].value
        
      } else {
        this.$message.warning('没有找到匹配当前指标的测量数据！')
      }
    },
    // 保存每次测量数据及测量指标匹配后的数据
    saveMeasurementForm() {
      let group = this.measurementForm.group
      console.log(this.measurementForm)
      if (!this.measurementForm.value) {
        alert('请先选择测量数据！')
        return
      }
      if (!group) {
        alert('请先选择指标！')
        return
      }
      this.dialogFormVisible = false
      // console.log(this.currentMeasurementData);
      let obj = {
        itemId: group[1],
        itemValue: parseFloat(this.measurementForm.value),
        uuid: this.measurementForm.annotationUuid,
        imageId: this.measurementForm.imageId
      }
      // 判断是否已经存在测量过的指标
      // 如果存在，则覆盖；
      // 不存在则直接插入
      this.addCacheMeasurementsItemByGroup(obj)
      // console.log(this.cacheSeriesMeasurements);
    },
    addCacheMeasurementsItemByGroup(obj) {
      const {itemId, itemValue, uuid} = obj
      let index = this.cacheSeriesMeasurements.findIndex((item) => item.itemId === itemId)
      if (index != -1) {
        // 同步删除图像上的标注
        this.deleteAnnotationByUuid(this.cacheSeriesMeasurements[index].uuid)
        this.cacheSeriesMeasurements.splice(index, 1, obj)
      } else {
        this.cacheSeriesMeasurements.push(obj)
      }
    },
    deleteCacheMeasurementsItemByUuid(uuid) {
      this.cacheSeriesMeasurements = this.cacheSeriesMeasurements.filter(item => item.uuid != uuid)
    },
    cancelMeasurementForm() {
      this.dialogFormVisible = false
      this.deleteAnnotationByUuid(this.currentMeasurementData.uuid)
    },
    getDicomContext() {
      this.menuItem = []
      this.$api.get('/v1/webconsole/pacs/getDicomContext').then((res) => {
        if (res.status == 200) {
          this.menuItem = res.data.data
          for (let menu of this.menuItem) {
            this.$parent.reportFrames[menu] = []
          }
        }
      })
    },
    // 加载图像通过imageId
    displayImageByImageId(imageId, dicom) {
      let cornerstone = this.$cornerstone
      let self = this
      try {
        cornerstone.loadAndCacheImage(imageId).then(
          function (image) {
            let viewport = cornerstone.getDefaultViewportForImage(self.elements[0], image)
            cornerstone.displayImage(self.elements[0], image, viewport)
            self.image = image
            self.$emit('changeImage', dicom)
          },
          function (err) {
            // alert('DicomPlay--DicomItemClick:' + err)
          }
        )
      } catch (err) {
        alert('DicomPlay--DicomItemClick--err:' + err)
      }
    },
    // 测量新增触发事件
    handleMeasurementAdd() {
      this.isMeasurementCompleted = false
      this.isNewMeasurement = true
    },
    // 检测测量有效数据有效性
    checkMeasurementValid(measurement) {
      // console.log(measurement);
      if (measurement.toolName === 'Length') {
        return !!measurement.length
      }
      if (measurement.toolName === 'Angle') {
        return !!measurement.rAngle
      }
      if (measurement.toolName === 'EllipticalRoi') {
        return !!measurement.cachedStats.area
      }
      if (measurement.toolName === 'FreehandRoi') {
        return true
      }
      return true
    },
    // 标注完成触发事件函数
    handleMeasurementComplete(eventData) {
      console.log('测量完成:', eventData)
      let mData = eventData.measurementData
      let obj = {}
      if (eventData.toolName == undefined) {
        return
      }
      if (eventData.toolName == 'TextMarker') {
        return
      }
      obj.uuid = eventData.measurementData.uuid
      obj.imageId = this.image.imageId
      if (eventData.toolName == 'Length') {
        obj.toolName = 'Length'
        obj.data = [
          {
            key: 'Length',
            value: mData.length.toFixed(2),
            unit: mData.unit,
            checked: false
          }
        ]
        this.currentMeasurementData = obj
      }
      if (eventData.toolName == 'Angle') {
        obj.toolName = 'Angle'
        obj.data = [
          {
            key: 'rAngle',
            value: `${mData.rAngle}`,
            checked: false
          }
        ]
        this.currentMeasurementData = obj
      }
      if (eventData.toolName == 'EllipticalRoi') {
        obj.toolName = 'EllipticalRoi'
        obj.data = [
          {
            key: 'Area',
            value: mData.cachedStats.area.toFixed(2),
            unit: 'mm²',
            checked: false
          },
          {
            key: 'Mean',
            value: mData.cachedStats.mean.toFixed(2),
            checked: false
          },
          {
            key: 'Max',
            value: mData.cachedStats.max,
            checked: false
          },
          {
            key: 'Min',
            value: mData.cachedStats.min,
            checked: false
          }
        ]
        this.currentMeasurementData = obj
      }
      if (eventData.toolName == 'RectangleRoi') {
        obj.toolName = 'RectangleRoi'
        obj.data = [
          {
            key: 'Area',
            value: mData.cachedStats.area.toFixed(2),
            unit: 'mm²',
            checked: false
          },
          {
            key: 'Perimeter',
            value: mData.cachedStats.perimeter.toFixed(2),
            unit: 'mm',
            checked: false
          },
          {
            key: 'Mean',
            value: mData.cachedStats.mean.toFixed(2),
            checked: false
          },
          {
            key: 'Variance',
            value: mData.cachedStats.variance.toFixed(2),
            checked: false
          }
        ]
        this.currentMeasurementData = obj
      }
      if (eventData.toolName == 'FreehandRoi') {
        obj.toolName = 'FreehandRoi'
        obj.data = [
          {
            key: 'Area',
            value: mData.area,
            unit: 'mm²',
            checked: false
          }
        ]
        this.currentMeasurementData = obj
      }
      this.dialogFormVisible = true

      // 询问是否需要保留测量记录
      // TODO
      // this.$alert('是否保留测量记录', '标题名称', {
      //   confirmButtonText: '确定',

      //   callback: action => {
      //     this.$message({
      //       type: 'info',
      //       message: `action: ${ action }`
      //     });
      //   }
      // });
      // 测量状态更新
      this.isMeasurementCompleted = true

      if (this.isNewMeasurement || !eventData.measurementData.annotation) {
        // 首次增加的测量
        this.isNewMeasurement = false
        const image = this.$cornerstone.getImage(eventData.element)
        let data = eventData.measurementData
        data.annotation = ''
        data.toolName = eventData.toolName
        data.toolType = eventData.toolType
        data.imageId = image.imageId
        data.dicom = this.dicom
        // for test

        const flag = this.measurementsList?.findIndex((item) => item.uuid === data.uuid)
        if (flag === -1 || flag === undefined) {
          // this.measurementsList.push(data)

          if (this.checkMeasurementValid(data)) {
            this.$emit('measurementCompleted', data)
          }
        }
      } else {
        // 对已存在的测量记录进行编辑
        // console.log('已经存在的后修改测量:', eventData.measurementData);
        let obj = Object.assign({}, eventData.measurementData)
        let formData = new FormData()
        formData.append('json', JSON.stringify(obj))
        // console.log('当前dicom:', this.dicom);
        this.$api
          .post('/v1/webconsole/pacsMeasure/savePacsMeasure', formData)
          .then(() => {
            // console.log(data);
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 更新测量标注列表
    // 砍了
    updateMeasurementsList(uuid) {
      const index = this.measurementsList.findIndex((item) => item.uuid === uuid)
      let deletedElms = this.measurementsList.splice(index, 1)
      this.$api
        .get('/v1/webconsole/pacsMeasure/deletePacsMeasure', {
          uuid: uuid
        })
        .then(() => {
          // console.log(data);
          // 如果当前的dicom的测量为空时
          if (this.measurementsList.length === 0) {
            this.$bus.$emit('finishedAnnotation', {
              type: 'clear',
              filePath: deletedElms[0].dicom.filePath
            })
          }
        })
        .catch((err) => console.log(err))
    },
    /**
     * 选中标注触发事件
     *
     * @param eventData 事件监听回调数据
     * @param type 1: 点击图像中的标注，关联列表中的测量记录； 2: 点击列表中的测量记录，关联图像中的标注
     * @param measurementObj type===2 时生效 列表中测量记录的itemdata
     * @returns void
     */
    handleMeasurementActivate(eventData = null, type = 1, measurementObj = null) {
      // _this.$bus.$emit('dicomMeasurementClick', eventData.measurementData.uuid)
      let element = this.$refs.DicomImage
      let cornerstoneTools = this.$cornerstoneTools
      let cornerstone = this.$cornerstone
      let currentImageId = cornerstone.getImage(element).imageId
      let globalToolState = cornerstoneTools.globalImageIdSpecificToolStateManager
      let currentToolState = globalToolState.toolState[currentImageId]
      if (type === 1) {
        const currentPoint = eventData.currentPoints.canvas
        // 匹配标注
        currentToolState &&
          Object.keys(currentToolState).forEach((key) => {
            currentToolState[key].data?.forEach((ele, i) => {
              const flag = cornerstoneTools[`${key}Tool`].prototype.pointNearTool(
                element,
                ele,
                currentPoint
              )
              if (flag) {
                // 不用了
                // this.$bus.$emit('dicomMeasurementActivate', currentToolState[key].data[i].uuid)
              }
            })
          })
      } else if (type === 2 && measurementObj != null) {
        // for test
        // console.log(measurementObj);
        const imageId = measurementObj.imageId
        const dicom = measurementObj.dicom || {
          uuid: measurementObj.dicomUuid,
          label: measurementObj.dicomLabel
        }
        const uuid = measurementObj.uuid
        let currentToolState = globalToolState.toolState[imageId]
        // let currentToolState = cornerstoneTools.getToolState(element, measurementObj.toolName)
        // console.log('imageIdToolState', globalToolState.getImageIdToolState(imageId, measurementObj.toolName));
        // let currentToolState =  globalToolState.getImageIdToolState(imageId, measurementObj.toolName)
        // currentToolState.data[0].active = true
        currentToolState &&
          Object.keys(currentToolState).forEach((key) => {
            currentToolState[key].data?.forEach((ele, i) => {
              if (ele.uuid === uuid) {
                // console.log('在toolState中的测量记录',currentToolState[key].data[i]);

                currentToolState[key].data[i].active = true

                this.displayImageByImageId(imageId, dicom)
                cornerstoneTools.setToolActiveForElement(
                  element,
                  currentToolState[key].data[i].toolName,
                  {
                    isMouseActive: false,
                    mouseButtonMask: 1
                  }
                )
                cornerstone.updateImage(element)
                // cornerstone.getEnabledElements().forEach(enabledElement => {
                //   console.log('enabledEle:', enabledElement);
                //   if (enabledElement.image) {
                //       cornerstoneTools.setToolActiveForElement(enabledElement.element, currentToolState[key].data[i].toolName, {
                //         isMouseActive: false,
                //         mouseButtonMask: 1
                //       })
                //       cornerstone.updateImage(enabledElement.element);
                //     // console.log('enabled-ele-image',enabledElement);
                //   }
                // });
              } else {
                currentToolState[key].data[i].active = false
              }
            })
          })
      }
    },
    // 删除标注
    deleteAnnotation() {
      const currentPoint = this.lastClickCoords
      const element = this.$refs.DicomImage
      const cornerstoneTools = this.$cornerstoneTools
      const cornerstone = this.$cornerstone
      const currentImageId = cornerstone.getImage(element).imageId
      const globalToolState = cornerstoneTools.globalImageIdSpecificToolStateManager
      const currentToolState = globalToolState.toolState[currentImageId]

      // console.log(currentImageId)
      

      // console.log('dicom', this.dicom);
      // console.log('image:', cornerstone.getImage(element));
      // console.log('imageMetaData', cornerstone.metaData.get('imagePlaneModule', currentImageId));
      // console.log('cornerstone-tools:', cornerstoneTools);
      // console.log('cornerstone:', cornerstone);
      // console.log('globalToolState' ,globalToolState);
      // console.log('currentToolState' ,currentToolState);

      // 匹配标注
      currentToolState &&
        Object.keys(currentToolState).forEach((key) => {
          currentToolState[key].data.forEach((ele, i) => {
            const flag = cornerstoneTools[`${key}Tool`].prototype.pointNearTool(
              element,
              ele,
              currentPoint
            )
            if (flag) {
              // this.updateMeasurementsList(currentToolState[key].data[i].uuid)
              // console.log('删除的测量标注的uuid:', currentToolState[key].data[i]);
              // 同时删除缓存中的测量
              this.deleteCacheMeasurementsItemByUuid(currentToolState[key].data[i].uuid)
              // 删除影像中的测量
              currentToolState[key].data.splice(i, 1)
              // this.$bus.$emit('measurementDelete', currentToolState[key].data[i].uuid)
              cornerstone.updateImage(element)
            }
          })
        })
      this.isShowContentMenu = false
    },
    // 通过 uuid 删除标注
    deleteAnnotationByUuid(uuid) {
      const currentPoint = this.lastClickCoords
      const element = this.$refs.DicomImage
      const cornerstoneTools = this.$cornerstoneTools
      const cornerstone = this.$cornerstone
      const currentImageId = cornerstone.getImage(element).imageId
      const globalToolState = cornerstoneTools.globalImageIdSpecificToolStateManager
      const currentToolState = globalToolState.toolState[currentImageId]

      // console.log('currentToolState', currentToolState);

      // 匹配标注
      currentToolState &&
        Object.keys(currentToolState).forEach((key) => {
          currentToolState[key].data.forEach((ele, i) => {
            if (ele.uuid === uuid) {
              // this.currentMeasurementData.uuid
              currentToolState[key].data.splice(i, 1)
            }
          })
        })
      this.isShowContentMenu = false
    },
    seek(percent) {
      this.frameIndex = Math.round((percent / 100) * this.framesCount)
      this.displayImage(this.frameIndex)
    },
    handleScrolle(e) {
      const direction = e.detail.deltaY > 0 ? 'down' : 'up'
      if (direction == 'down') {
        if (this.frameIndex < this.framesCount - 1) {
          this.frameIndex += 1
        } else {
          this.frameIndex = 0
        }
      }
      if (direction == 'up') {
        if (this.frameIndex > 0) {
          this.frameIndex -= 1
        } else {
          this.frameIndex = this.framesCount - 1
        }
      }
      this.displayImage(this.frameIndex)
    },
    setDicom(dicom) {
      console.log('DicomViewer-dicom:', dicom);
      if (this.timerId) clearInterval(this.timerId)
      this.dicom = dicom
      this.frameIndex = 0
      this.displayImage(this.frameIndex)
    },
    _click(event) {
      console.log(event)
      console.log(this.$parent.dicomViewerMax)
      this.$emit('dicomViewClick', this)
      // if (this.$parent.dicomViewerMax === 1) {
      //   this.$emit('dicomViewClick', this)
      //   return
      // } else {

      // }

      // 一种阻止事件穿透的尝试，但是好像不行 fuck
      // if (this.firstSelected) {
      //   let toolList = ['EllipticalRoi', 'Length', 'Angle', 'FreehandRoi',]
      //   let cornerstoneTools = this.$cornerstoneTools
      //   toolList.forEach((item) => {
      //     let flag = cornerstoneTools.isToolActiveForElement(this.elements[0], item)
      //     if (flag) {
      //       // console.log(`${item}激活的, setEnabled`);
      //       cornerstoneTools.setToolEnabled(item)
      //       this.$bus.$emit('dicomViewerChanged')
      //     }
      //   })
      //   this.$emit('dicomViewClick', this)
      //   // let dicomViewer = document.getElementById('dicom-viewer')
      //   event.stopPropagation()
      //   this.firstSelected = false
      // } else if (!this.firstSelected) {
      //   delete event.stopPropagation
      // }
    },
    displayImage(index) {
      if (index < 0 || index >= this.framesCount) return
      let self = this
      let cornerstone = this.$cornerstone
      try {
        cornerstone.loadAndCacheImage(this.dicom.imageIds[index]).then(
          function (image) {
            let viewport = cornerstone.getDefaultViewportForImage(self.elements[0], image)
            cornerstone.displayImage(self.elements[0], image, viewport)
            self.image = image
          },
          function (err) {
            // alert('DicomPlay--DicomItemClick:' + err)
          }
        )
      } catch (err) {
        alert('DicomPlay--DicomItemClick--err:' + err)
      }
    },
    loop() {
      if (this.startFrame >= this.finishFrame) {
        alert('开始帧要小于结束帧才能进行区间循环播放')
      } else {
        if (this.timerId) clearInterval(this.timerId)
        this.status = 'loop'
        this.timerId = setInterval(this._loopPlayFunc.bind(this), 1000 / this.frameRate)
      }
    },
    _loopPlayFunc() {
      let step = 1
      this.frameIndex = this.frameIndex + step
      if (this.frameIndex >= this.finishFrame) {
        this.frameIndex = this.startFrame - 1
      } else if (this.frameIndex < this.startFrame) {
        this.frameIndex = this.finishFrame - 1
      }
      this.displayImage(this.frameIndex)
    },
    play() {
      if (this.timerId) clearInterval(this.timerId)
      this.order = 'positive'
      this.status = 'play'
      this.timerId = setInterval(this._playFunc.bind(this), 1000 / this.frameRate)
    },
    _playFunc() {
      let step = this.order === 'positive' ? 1 : -1
      this.frameIndex = this.frameIndex + step
      if (this.frameIndex >= this.framesCount) {
        this.frameIndex = 0
      } else if (this.frameIndex < 0) {
        this.frameIndex = this.framesCount - 1
      }
      this.displayImage(this.frameIndex)
    },
    stop() {
      if (this.timerId) clearInterval(this.timerId)
      this.status = 'stop'
      this.frameIndex = 0
      this.displayImage(this.frameIndex)
    },
    pause() {
      if (this.timerId) clearInterval(this.timerId)
      this.status = 'pause'
    },
    reversePlay() {
      if (this.timerId) clearInterval(this.timerId)
      this.order = 'reverse'
      this.status = 'play'
      this.timerId = setInterval(this._playFunc.bind(this), 1000 / this.frameRate)
    },
    first() {
      this.frameIndex = 0
      this.displayImage(this.frameIndex)
    },
    pre() {
      this.frameIndex = Math.max(0, this.frameIndex - 1)
      this.displayImage(this.frameIndex)
    },
    next() {
      this.frameIndex = Math.min(this.framesCount - 1, this.frameIndex + 1)
      this.displayImage(this.frameIndex)
    },
    last() {
      this.frameIndex = this.framesCount - 1
      this.displayImage(this.frameIndex)
    },
    datatimeFormat(date, time, split = '-') {
      let datetime = ''
      if (date) {
        datetime = date.substr(0, 4) + split + date.substr(4, 2) + split + date.substr(6, 2)
      }
      if (time) {
        if (datetime !== '') datetime = datetime + ' '
        datetime = datetime + time.substr(0, 2) + ':' + time.substr(2, 2) + ':' + time.substr(4, 2)
      }
      return datetime
    },
    showContentMenu(event) {
      const [left, top] = getPosition(event)
      const clientHeight = this.$refs.DicomImage.clientHeight
      const clientWidth = this.$refs.DicomImage.clientWidth
      const menuHeight = this.$refs.ContentMenu.clientHeight
      const menuWidth = this.$refs.ContentMenu.clientWidth
      this.positionStyleText = {
        zIndex: PopupManager.nextZIndex()
      }
      if (left + menuWidth > clientWidth) {
        this.positionStyleText.left = left - menuWidth + 'px'
      } else {
        this.positionStyleText.left = left + 'px'
      }
      if (top + menuHeight > clientHeight) {
        this.positionStyleText.top = top - menuHeight + 'px'
      } else {
        this.positionStyleText.top = top + 'px'
      }
      // let width = 0
      // for (let menu of this.menuItem) {
      //   width = Math.max(width, menu.length)
      // }
      // this.positionStyleText.width = width + 'em'
      this.isShowContentMenu = true
      return true
    },
    handleOutside() {
      if (this.isShowContentMenu) this.isShowContentMenu = false
    },
    addImage(event) {
      let menuItem = event.target.innerText
      if (menuItem.includes('添加到')) {
        let pacsPrint = new PACSPrint()
        pacsPrint.lesionName = menuItem.replace(/.{4}$/g, '').trim()
        console.log(pacsPrint.lesionName)
        pacsPrint.addReport = 1
        const canvas = this.$refs.DicomImage.querySelector('canvas')
        pacsPrint.base64Data = createNewImage(canvas, this.image, this.elements[0])
        // pacsPrint.base64Data = canvas.toDataURL('image/png', 1);
        let selectedLession = menuItem.replace(/^.{3}|.{4}$/g, '').trim()
        console.log(selectedLession)
        this.$parent.selectedTab = 'Report Frames'
        this.$parent.selectedLession = selectedLession
        this.$api.post('/v1/webconsole/pacs/receiveImage', pacsPrint).then((res) => {
          console.log(res)
          let frames = this.$parent.reportFrames[selectedLession]
          frames.push(pacsPrint.base64Data)
          this.$parent.$forceUpdate()
        })
      }
      // if ('添加到冠脉造影' == menuItem) {
      //   let pacsPrint = new PACSPrint()
      //   pacsPrint.lesionName = '添加到冠脉造影'
      //   pacsPrint.base64Data = createImage(this.image)
      //   this.$parent.selectedTab = 'Report Frames'
      //   this.$parent.selectedLession = '冠脉造影'
      //   this.$api.post('/v1/webconsole/pacs/receiveImage', pacsPrint).then(() => {
      //     let frames = this.$parent.reportFrames['冠脉造影'] || []
      //     frames.push(pacsPrint.base64Data)
      //     this.$set(this.$parent.reportFrames, '冠脉造影', frames)
      //   })
      // } else if ('添加到XXX' == menuItem) {
      //   this.$parent.selectedTab = 'Report Frames'
      //   this.$parent.selectedLession = 'XXX'
      //   let frames = this.$parent.reportFrames['XXX'] || []
      //   frames.push(createImage(this.image))
      //   this.$set(this.$parent.reportFrames, 'XXX', frames)
      // }
      this.isShowContentMenu = false
    },
    playClick(flag) {
      if (flag) this.play()
      else this.pause()
    }
  }
}
</script>
