<style scoped lang="less">
.child-button-panel {
  background-color: #fafafa;
  border: 1px solid #729cd3;
  position: absolute;
  display: flex;
  float: left;
  flex-direction: column;
  z-index: 10;
  margin-left: 6px;
  flex-wrap: wrap;
  height: 80px;
  width: 420px;
}
.inner-image-button {
  position: relative;
  width: 60px;
  height: 40px;
  padding: 0px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-color: #dcdfe6;
  color: #606266;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: 0.1s;
  font-weight: 500;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 14px;
  border-radius: 4px;
}
.inner-image-button:hover {
  border-color: #3b90eb;
  background: linear-gradient(white, #ffbd79, white);
  outline: 0;
}
.inner-image-button-on {
  border-color: #3b90eb;
  background: linear-gradient(white, #ffbd79, white);
  outline: 0;
}
</style>

<template>
  <div v-clickoutside="handleClose">
    <button
      class="image-button"
      :class="[
        buttonIconClass,
        {
          'image-button-on': isFocus
        }
      ]"
      @focus="_focus"
    ></button>
    <div class="child-button-panel" @click="_click" v-if="isFocus">
      <button
        class="inner-image-button"
        v-for="(btn, index) in childrenButton"
        :class="[
          btn.buttonIconClass || '',
          {
            'inner-image-button-on': value == btn.dataTool
          }
        ]"
        :data-tool="btn.dataTool || ''"
        :key="index"
      ></button>
    </div>
  </div>
</template>

<script>
import Clickoutside from 'element-ui/src/utils/clickoutside'
export default {
  name: 'CmPanelButton',
  components: {},
  model: {
    prop: 'value',
    event: 'click'
  },
  directives: {
    Clickoutside
  },
  props: {
    buttonIconClass: {
      type: String,
      default: ''
    },
    childrenButton: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isFocus: false
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    _focus() {
      this.isFocus = true
    },
    handleClose() {
      if (!this.isFocus) return
      this.isFocus = false
    },
    _click(e) {
      this.$emit('click', e.target.dataset?.tool)
      this.isFocus = false
    }
  }
}
</script>
