export default {
  x00020000: 'FileMetaInformationGroupLength',
  x00020001: 'FileMetaInformationVersion',
  x00020002: 'MediaStorageSOPClassUID',
  x00020003: 'MediaStorageSOPInstanceUID',
  x00020010: 'TransferSyntaxUID',
  x00020012: 'ImplementationClassUID',
  x00020013: 'ImplementationVersionName',
  x00020016: 'SourceApplicationEntityTitle',
  x00080005: 'SpecificCharacterSet',
  x00080008: 'ImageType',
  x00080016: 'SOPClassUID',
  x00080018: 'SOPInstanceUID',
  x00080020: 'StudyDate',
  x00080021: 'SeriesDate',
  x00080022: 'AcquisitionDate',
  x00080023: 'ContentDate',
  x00080030: 'StudyTime',
  x00080031: 'SeriesTime',
  x00080032: 'AcquisitionTime',
  x00080033: 'ContentTime',
  x00080060: 'Modality',
  x00080070: 'Manufacturer',
  x00080080: 'InstitutionName',
  x00080081: 'InstitutionAddress',
  x00080090: 'ReferringPhysicianName',
  x00081010: 'StationName',
  x00081030: 'StudyDescription',
  x0008103e: 'SeriesDescription',
  x00081090: 'ManufacturerModelName',
  x00081110: 'ReferencedStudySequence',
  x00081120: 'ReferencedPatientSequence',
  x00081150: 'ReferencedSOPClassUID',
  x00081155: 'ReferencedSOPInstanceUID',
  x00082111: 'DerivationDescription',
  x00082142: 'StartTrim',
  x00082143: 'StopTrim',
  x00082144: 'RecommendedDisplayFrameRate',
  x00100010: 'PatientName',
  x00100020: 'PatientID',
  x00100030: 'PatientBirthDate',
  x00100040: 'PatientSex',
  x00101010: 'PatientAge',
  x00180040: 'CineRate',
  x00180060: 'KVP',
  x00181000: 'DeviceSerialNumber',
  x00181020: 'SoftwareVersions',
  x00181030: 'ProtocolName',
  x00181063: 'FrameTime',
  x00181110: 'DistanceSourceToDetector',
  x00181111: 'DistanceSourceToPatient',
  x00181114: 'EstimatedRadiographicMagnificationFactor',
  x00181150: 'ExposureTime',
  x00181151: 'XRayTubeCurrent',
  x00181154: 'AveragePulseWidth',
  x00181155: 'RadiationSetting',
  x0018115a: 'RadiationMode',
  x0018115e: 'ImageAndFluoroscopyAreaDoseProduct',
  x00181162: 'IntensifierSize',
  x00181164: 'ImagerPixelSpacing',
  x00181190: 'FocalSpots',
  x00181500: 'PositionerMotion',
  x00181510: 'PositionerPrimaryAngle',
  x00181511: 'PositionerSecondaryAngle',
  x00181600: 'ShutterShape',
  x00181602: 'ShutterLeftVerticalEdge',
  x00181604: 'ShutterRightVerticalEdge',
  x00181606: 'ShutterUpperHorizontalEdge',
  x00181608: 'ShutterLowerHorizontalEdge',
  x00181700: 'CollimatorShape',
  x00181702: 'CollimatorLeftVerticalEdge',
  x00181704: 'CollimatorRightVerticalEdge',
  x00181706: 'CollimatorUpperHorizontalEdge',
  x00181708: 'CollimatorLowerHorizontalEdge',
  x00185100: 'PatientPosition',
  x00187006: 'DetectorDescription',
  x0018700a: 'DetectorID',
  x00190010: 'PrivateCreator',
  x0020000d: 'StudyInstanceUID',
  x0020000e: 'SeriesInstanceUID',
  x00200010: 'StudyID',
  x00200011: 'SeriesNumber',
  x00200012: 'AcquisitionNumber',
  x00200013: 'InstanceNumber',
  x00200020: 'PatientOrientation',
  x00201002: 'ImagesInAcquisition',
  x00210010: 'PrivateCreator',
  x00230010: 'PrivateCreator',
  x00250010: 'PrivateCreator',
  x00280002: 'SamplesPerPixel',
  x00280004: 'PhotometricInterpretation',
  x00280008: 'NumberOfFrames',
  x00280009: 'FrameIncrementPointer',
  x00280010: 'Rows',
  x00280011: 'Columns',
  x00280100: 'BitsAllocated',
  x00280101: 'BitsStored',
  x00280102: 'HighBit',
  x00280103: 'PixelRepresentation',
  x00281040: 'PixelIntensityRelationship',
  x00281050: 'WindowCenter',
  x00281051: 'WindowWidth',
  x00281056: 'VOILUTFunction',
  x00282110: 'LossyImageCompression',
  x00286010: 'RepresentativeFrameNumber',
  x00290010: 'PrivateCreator',
  x00290011: 'PrivateCreator',
  x00320012: 'RETIRED_StudyIDIssuer',
  x00321060: 'RequestedProcedureDescription',
  x00400244: 'PerformedProcedureStepStartDate',
  x00400245: 'PerformedProcedureStepStartTime',
  x00400253: 'PerformedProcedureStepID',
  x00400254: 'PerformedProcedureStepDescription',
  x00400275: 'RequestAttributesSequence',
  x00400007: 'ScheduledProcedureStepDescription',
  x00400009: 'ScheduledProcedureStepID',
  x00401001: 'RequestedProcedureID',
  x00403001: 'ConfidentialityConstraintOnPatientDataDescription',
  x7fe00010: 'PixelData'
}
