<style scoped lang="less"></style>

<template>
  <el-table
    :data="getTagsList"
    border
    :highlight-current-row="true"
    :fit="true"
    height="100%"
  >
    <el-table-column
      label="(Group,Element)"
      width="100px"
      :formatter="splitTag"
    >
    </el-table-column>
    <el-table-column
      label="Tag Description"
      width="200px"
      :formatter="getTagDescription"
    >
    </el-table-column>
    <el-table-column
      label="Value"
      :resizable="true"
      :formatter="getTagValue"
      :show-overflow-tooltip="true"
      min-width="300px"
    >
    </el-table-column>
    <div slot="empty"></div>
  </el-table>
</template>

<script>
import readTagValueFromDataSet from '../../../../assets/js/readTagValueFromDataSet'
import DICOM_TAG_DICT from '../../../../assets/js/dicomTagDescription'
export default {
  name: 'AllTagsList',
  components: {},
  props: {
    dataSet: {
      type: Object,
      default: null
    }
  },
  data () {
    return {}
  },
  computed: {
    getTagsList: function () {
      if (!this.dataSet) return []
      else {
        return Object.keys(this.dataSet.elements).sort()
      }
    }
  },
  watch: {},
  created () { },
  mounted () { },
  methods: {
    splitTag (tag) {
      let group = tag.substr(1, 4)
      let element = tag.substr(5, 4)
      return `(${group},${element})`
    },
    getTagDescription (tag) {
      if (tag in DICOM_TAG_DICT) return DICOM_TAG_DICT[tag]
      return 'Unknown Tag & Data'
    },
    getTagValue (tag) {
      return readTagValueFromDataSet(this.dataSet, tag)
    }
  }
}
</script>
