let CornaryInfo = function () {
  this.CbGmysx = ''
  this.CbJxgm = ''
  this.CbCzxg = ''
  this.CbCabgs = ''
  this.CbShzd = '' //术后诊断
  this.CbBfz = ''
  this.CbBhff = ''
  this.CbZhidong = '' //术后制动
  this.TbBgsj = ''
  this.TbFsjl = ''
  this.cbPhysician = ''
  this.cbPuncture = ''
  this.CbGmjd = ''
  this.CbOtherIntervention = ''
  this.TbCxl = ''
  this.txtSpecialSuitation = ''
}
let CornaryJRZLInfo = function () {
  this.CbCyss = ''
  this.CbBwjr = ''
  this.TbZlhxzcd = ''

  this.CbZlhtimi = ''
  this.CbZrzj = ''
  this.CbSzbfz = ''
}

let CornaryIVUSInfo = function () {
  //IVUS前
  this.CbIVUSsj1 = ''
  this.MtbGqzxzj1 = '0'
  this.MtbGqzdzj1 = '0'
  this.MtbGqzxmj1 = '0'
  this.MtbEEMZxzj1 = '0'
  this.MtbEEMZdzj1 = '0'
  this.MtbEEMMj1 = '0'
  this.MtbBkfh1 = '0'

  //IVUS后
  this.CbIVUSsj2 = ''
  this.MtbGqzxzj2 = '0'
  this.MtbGqzdzj2 = '0'
  this.MtbGqzxmj2 = '0'
  this.MtbEEMZxzj2 = '0'
  this.MtbEEMZdzj2 = '0'
  this.MtbEEMMj2 = '0'
  this.MtbBkfh2 = '0'
}

let CornaryOCTInfo = function () {
  //OCT前
  this.CbOCTsj1 = ''
  this.MtbGqzxzj3 = '0'
  this.MtbGqzdzj3 = '0'
  this.MtbGqzxmj3 = '0'
  this.MtbEEMZxzj3 = '0'
  this.MtbEEMZdzj3 = '0'
  this.MtbEEMMj3 = '0'
  this.MtbBkfh3 = '0'

  //OCT前
  this.CbOCTsj2 = ''
  this.MtbGqzxzj4 = '0'
  this.MtbGqzdzj4 = '0'
  this.MtbGqzxmj4 = '0'
  this.MtbEEMZxzj4 = '0'
  this.MtbEEMZdzj4 = '0'
  this.MtbEEMMj4 = '0'
  this.MtbBkfh4 = '0'
}

let CornaryFFRInfo = function () {
  this.TbFFR1 = '0'
  this.TbFFR2 = '0'
  this.CbSqty = ''
  this.CbShty = ''
}

let CornaryROTAInfo = function () {
  this.CbMt = ''
  this.TbZs = ''
  this.CbJkxg = ''
}

let CornaryThrombus = function () {
  this.Time = ''
  this.Count = ''
  this.Number = ''
  this.Type = ''
}

let CoronaryProcess = function () {
  this.Process = ''
  this.StudyResult = ''
  this.PostoperativeAdvice = ''
}

let AneurysmInfo = function () {
  // typeOfTechnique
  this.neurointerventionIndex = null
  this.isHaslesions = null
  this.punctureSite = null
  this.treatmentHistory = null
  this.hasRupture = null
  this.aneurysmCount = null
  this.dominantSide = null
  this.closureMethod = null
  this.complication = null
  this.postBrake = null
  this.radiationDose = null
  this.exposureTime = null
  this.timeOfAdmission = null
  this.timingOfTreatment = null
  this.huntHessScore = null
  this.gCSScore = null
  this.wFNsScore = null
  this.mFisher = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.examineDoctor = null
  this.assistantDoctor1 = null
  this.assistantDoctor2 = null
  this.timeFromOnsetToPuncture = null
  this.punctureToOpeningTime = null
  this.dangerousAnastomosis = null
  this.studyRecord = null
  this.studyResult = null
  this.postoperativeAdvice = null
  this.preoperativeAssessment = null
}

let AneurysmBloodVesselInfo = function () {
  this.detailIndex = null
  this.neurointerventionIndex = null
  this.lesion = null
  this.aneurysmType = null
  this.isHas = null
  this.maxDiameter = null
  this.neckWidth = null
  this.tumorWidth = null
  this.tumorHeight = null
  this.type = null
  this.regularity = null
  this.interventionHistory = null
  this.implantHistory = null
  this.bracketType = null
  this.bracketCount = null
  this.springCoilCount = null
  this.handlingSuggestions = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.lesionOrder = null
}

let AneurysmSpringBalloonCoilPlug = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degree = null
  this.count = null
  this.complication = null
  this.standCount = null
  this.standType = null
}

let AneurysmSpringCoil = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degree = null
  this.count = null
  this.complication = null
}

let AneurysmSpringCraniotomyClipping = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degree = null
  this.count = null
  this.complication = null
  this.standCount = null
  this.standType = null
}

let AneurysmSpringStandCoilPlug = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degree = null
  this.count = null
  this.complication = null
  this.standCount = null
  this.standType = null
}

let ArteriovernousMalformationBloodVesselInfo = function () {
  this.detailIndex = null
  this.neurointerventionIndex = null
  this.lesion = null
  this.arteryName = null
  this.arteryCount = null
  this.veinName = null
  this.veinCount = null
  this.isMerge = null
  this.aVMType = null
  this.aVMLevel = null
  this.aVMSize = null
  this.brainArea = null
  this.method = null
  this.interventionHistory = null
  this.implantHistory = null
  this.springCoilType = null
  this.springCoilCount = null
  this.onyxCount = null
  this.cyclicGrading = null
  this.handlingSuggestions = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.lesionOrder = null
}

let AbnormalEmbolism = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.residualDeformityMass = null
  this.coilCount = null
  this.onyxCount = null
  this.postoperativeComplications = null
}

let GammaKnife = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.postoperativeComplications = null
  this.malformationMassOcclusion = null
  this.targetCount = null
  this.centralDose = null
  this.marginalDose = null
}

let MicrosurgicalTreatment = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.residualDeformityMass = null
  this.postoperativeComplications = null
}

let ArterialStenosisBloodVesselInfo = function () {
  this.detailIndex = null
  this.neurointerventionIndex = null
  this.lesion = null
  this.stenosisDegree = null
  this.stenosisLength = null
  this.features = null
  this.timi = null
  this.lesionClassification = null
  this.interventionHistory = null
  this.implantHistory = null
  this.bracketType = null
  this.bracketCount = null
  this.medicineBallCount = null
  this.handlingSuggestions = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.lesionOrder = null
}

let BalloonDilatation = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degreeOfStenosis = null
  this.postoperativeComplications = null
  this.tIMIBlood = null
  this.balloonDilatationCount = null
}

let StentImplantation = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degreeOfStenosis = null
  this.postoperativeComplications = null
  this.tIMIBlood = null
  this.balloonDilatationCount = null
}

let CerebralEmbolismBloodVesselInfo = function () {
  this.detailIndex = null
  this.neurointerventionIndex = null
  this.location = null
  this.count = null
  this.nIHSS = null
  this.interventionHistory = null
  this.bracketHistory = null
  this.bracketType = null
  this.handlingSuggestions = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.lesionOrder = null
}

let CerebralEmbolismThrombolysis = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.thrombolysisType = null
  this.degreeOfStenosis = null
  this.postoperativeComplications = null
  this.tIMIBloodLevel = null
  this.thrombolyticDrugsType = null
  this.nIHSSGrade = null
}

let CerebralEmbolismEmbolectomy = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degreeOfStenosis = null
  this.postoperativeComplications = null
  this.useAntiplateletDrugs = null
  this.mTICILevel = null
  this.nIHSSGrade = null
  this.thrombusVolume = null
  this.drawBoltCount = null
  this.bracketCount = null
  this.bracketType = null
}

let CerebralEmbolismBalloonDilatation = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degreeOfStenosis = null
  this.postoperativeComplications = null
  this.useAntiplateletDrugs = null
  this.mTICILevel = null
  this.nIHSSGrade = null
  this.thrombusVolume = null
  this.drawBoltCount = null
  this.bracketCount = null
  this.bracketType = null
}

let CerebralEmbolismStentImplantation = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.degreeOfStenosis = null
  this.postoperativeComplications = null
  this.useAntiplateletDrugs = null
  this.mTICILevel = null
  this.nIHSSGrade = null
  this.thrombusVolume = null
  this.drawBoltCount = null
  this.bracketCount = null
  this.bracketType = null
}

let CCFBloodVesselInfo = function () {
  this.detailIndex = null
  this.neurointerventionIndex = null
  this.lesion = null
  this.comorbidities = null
  this.location = null
  this.arteryName = null
  this.veinName = null
  this.veinType = null
  this.interventionHistory = null
  this.implantHistory = null
  this.bracketType = null
  this.bracketCount = null
  this.onyxCount = null
  this.balloonCount = null
  this.handlingSuggestions = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.lesionOrder = null
}

let CCFCoilPlug = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.postoperativeComplications = null
  this.treatmentEffect = null
  this.coilCount = null
}

let CCFReleasableBalloon = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.postoperativeComplications = null
  this.treatmentEffect = null
  this.balloonCount = null
}

let CCFOnyx = function () {
  this.id = null
  this.detailIndex = null
  this.recordCode = null
  this.recordName = null
  this.recordDate = null
  this.isSuccess = null
  this.postoperativeComplications = null
  this.treatmentEffect = null
  this.onyxConsumption = null
}

let PACSPrint = function () {
  this.lesionName = null
  this.base64Data = null
  this.addReport = 0
}

export {
  CornaryInfo,
  CornaryJRZLInfo,
  CornaryIVUSInfo,
  CornaryOCTInfo,
  CornaryFFRInfo,
  CornaryROTAInfo,
  CornaryThrombus,
  CoronaryProcess,
  AneurysmInfo,
  AneurysmBloodVesselInfo,
  AneurysmSpringBalloonCoilPlug,
  AneurysmSpringCoil,
  AneurysmSpringCraniotomyClipping,
  AneurysmSpringStandCoilPlug,
  ArteriovernousMalformationBloodVesselInfo,
  AbnormalEmbolism,
  GammaKnife,
  MicrosurgicalTreatment,
  ArterialStenosisBloodVesselInfo,
  BalloonDilatation,
  StentImplantation,
  CerebralEmbolismBloodVesselInfo,
  CerebralEmbolismThrombolysis,
  CerebralEmbolismEmbolectomy,
  CerebralEmbolismBalloonDilatation,
  CerebralEmbolismStentImplantation,
  CCFBloodVesselInfo,
  CCFCoilPlug,
  CCFOnyx,
  CCFReleasableBalloon,
  PACSPrint
}
