import { render, staticRenderFns } from "./DicomViewer.vue?vue&type=template&id=ce9e7d18&scoped=true"
import script from "./DicomViewer.vue?vue&type=script&lang=js"
export * from "./DicomViewer.vue?vue&type=script&lang=js"
import style0 from "./DicomViewer.vue?vue&type=style&index=0&id=ce9e7d18&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce9e7d18",
  null
  
)

export default component.exports