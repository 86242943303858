import { generateLut } from 'cornerstone-core'
import * as cornerstone from 'cornerstone-core'

function createImage (image) {
  var columns = image.columns
  var rows = image.rows
  var canvas = document.createElement('canvas')
  canvas.width = image.columns
  canvas.height = image.rows
  var context = canvas.getContext('2d')
  context.fillStyle = 'white'
  context.fillRect(0, 0, canvas.width, canvas.height)
  var canvasData = context.getImageData(0, 0, columns, rows)
  var pixelData = image.getPixelData()
  var minPixelValue = image.minPixelValue
  var canvasImageDataIndex = 3
  var storedPixelDataIndex = 0
  var localNumPixels = pixelData.length
  var localPixelData = pixelData
  var localLut = generateLut(image, image.windowWidth, image.windowCenter, image.invert, image.modalityLUT, image.voiLUT)
  var imageData = canvasData
  var localCanvasImageDataData = canvasData.data
  if (minPixelValue < 0) {
    while (storedPixelDataIndex < localNumPixels) {
      localCanvasImageDataData[canvasImageDataIndex] = localLut[localPixelData[storedPixelDataIndex++] + (-minPixelValue)] // alpha
      canvasImageDataIndex += 4
    }
  } else {
    while (storedPixelDataIndex < localNumPixels) {
      localCanvasImageDataData[canvasImageDataIndex] = localLut[localPixelData[storedPixelDataIndex++]] // alpha
      canvasImageDataIndex += 4
    }
  }
  context.putImageData(imageData, 0, 0)
  return canvas.toDataURL('image/jpeg')
}

/**
 * 创建base64图片
 * @param {*} sourceCanvas 挂载在当前激活的element的canvas标签对象
 * @param {*} image 当前渲染的image
 * @param {*} ele 激活的element
 * @param {*} type 图片类型
 * @returns 
 */
function createNewImage(sourceCanvas, image, ele, type='png') {
  let viewport = cornerstone.getViewport(ele)
  let scale = viewport.scale

  let targetCanvas = document.createElement('canvas')
  let sourceCtx = sourceCanvas.getContext('2d')
  let targetCtx = targetCanvas.getContext('2d')

  let columns = image.columns * scale
  let rows = image.rows * scale
  
  targetCanvas.width = columns
  targetCanvas.height = rows
  // 复制绘图上下文
  targetCtx.fillStyle = sourceCtx.fillStyle
  targetCtx.strokeStyle = sourceCtx.strokeStyle

  // 源 Canvas 宽高
  const sourceWidth = sourceCanvas.width;
  const sourceHeight = sourceCanvas.height;

  // 目标 Canvas 宽高
  // const targetWidth = targetCanvas.width;
  // const targetHeight = targetCanvas.height;

  // 计算中间区域的位置
  const sourceX = (sourceWidth - columns) / 2;
  const sourceY = (sourceHeight - rows) / 2

  // 在目标 Canvas 上绘制中间区域
  targetCtx.drawImage(sourceCanvas, sourceX, sourceY, columns, rows, 0, 0, columns, rows);
  
  return targetCanvas.toDataURL(`image/${type}`)
}

function downloadImage (image, filename, canvas, ele, type) {
  let a = document.createElement('a')
  // a.setAttribute('href', createImage(image))
  a.setAttribute('href', createNewImage(canvas, image, ele, type))
  a.download = filename + '.jpeg'
  a.rel = 'noopener noreferrer'
  document.body.append(a)
  a.click()
  document.body.removeChild(a)
}

function downloadDCM (dicom) {
  if (dicom instanceof File) {
    alert('该文件已是本地文件')
    return
  }
  try {
    let a = document.createElement('a')
    let url = dicom.url || dicom.filePath
    a.setAttribute('href', url)
    let filename = url.split('/').pop()
    a.download = filename
    a.rel = 'noopener noreferrer'
    document.body.append(a)
    a.click()
    document.body.removeChild(a)
  } catch (err) {
    console.log(err);
  }
}

export {
  createImage,
  createNewImage,
  downloadImage,
  downloadDCM
}
