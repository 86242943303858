<template>
  <div>
    <div
      class="coronaryImgBox"
      v-show="showCoronaryImg"
      ref="choose"
      @mousemove="mousemove"
      @mouseleave="mouseleave"
    >
      <!-- <div class="imgLabel" v-show="labelIsShow">{{ imgLabel }}</div> -->
      <div v-show="showimgage" class="mask" ref="mask" @click="handleClickGmtImg"></div>
      <img ref="gmtImage" :src="gmtImg" alt="" />
    </div>
    <div v-show="showimgage" class="coronaryImgBigBox" ref="lagrebox">
      <img :src="gmtImg" ref="large" alt="" />
      <div class="line"></div>
      <div class="line2"></div>
    </div>
    <!-- <div class="closeImg" v-show="showCoronaryImg" @click="closeImg">
      <i class="el-icon-circle-close"></i>
    </div> -->
    <div class="openImg" v-show="openImgTag" @click="openImg">
      <i class="el-icon-picture-outline"></i>
    </div>
  </div>
</template>
<style lang="less" scoped>
.coronaryImgBox {
  width: 560px;
  height: 320px;
  // position: absolute;
  // top: 60%;
  // left: 10px;
  // z-index: 99;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #ccc;
  .imgLabel {
    position: absolute;
    top: -28px;
    left: 10px;
    height: 28px;
    line-height: 28px;
    padding: 0 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: rgb(255, 255, 255);
  }
  .mask {
    width: 100px;
    height: 100px;
    background: #89cff0;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
}
.closeImg {
  z-index: 99;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background: #409eff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  top: 61%;
  left: 590px;
  cursor: pointer;
  i {
    color: #fff;
  }
}
.openImg {
  z-index: 99;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background: #409eff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  top: 61%;
  left: 0;
  cursor: pointer;
  i {
    color: #fff;
  }
}
.coronaryImgBigBox {
  width: 500px;
  height: 500px;
  position: absolute;
  border-radius: 8px;
  top: 40%;
  left: 600px;
  z-index: 99;
  overflow: hidden;
  img {
    width: 200%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .line {
    background: #e7e7e7; /*背景色为浅灰色*/
    width: 1px; /*设置宽高*/
    height: 100px;
    position: relative; /*调整位置*/
    top: 200px;
    left: 250px;
  }
  .line2 {
    background: #e7e7e7; /*背景色为浅灰色*/
    width: 100px; /*设置宽高*/
    height: 1px;
    position: relative; /*调整位置*/
    top: 150px;
    left: 200px;
  }
}
</style>
<script>
export default {
  name: 'gmtImgBox',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      //
      showCoronaryImg: true,
      gmtImg: require('@/assets/img/gmt.png'),
      showimgage: false,
      labelIsShow: false,
      openImgTag: true,
      imgLabel: '',
      showLabelDebounced: null, // 添加防抖函数
      allInfo: null,
      operation: 'CoronaryInfo'
    }
  },
  watch: {
    isShow: {
      handler(val) {
        if (val) {
          this.openImgTag = true
        } else {
          this.openImgTag = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.showLabelDebounced = this.debounce(this.showLabel, 500)
    // this.getDefaultInfo()
  },
  methods: {
    closeImg() {
      this.showCoronaryImg = false
      this.openImgTag = true
    },
    openImg() {
      this.showCoronaryImg = true
      this.openImgTag = false
    },
    getPosition(val) {
      var dc = document,
        rec = val.getBoundingClientRect(),
        x = rec.left, // 获取元素相对浏览器视窗window的左、上坐标
        y = rec.top
      x += dc.documentElement.scrollLeft || dc.body.scrollLeft
      y += dc.documentElement.scrollTop || dc.body.scrollTop
      return {
        left: x,
        top: y
      }
    },
    mousemove(event) {
      this.showimgage = true
      let pos = this.getPosition(this.$refs.choose)
      // 获取图片
      let choose = this.$refs.choose
      let mask = this.$refs.mask
      let gmtImage = this.$refs.gmtImage
      let large = this.$refs.large
      let lagrebox = this.$refs.lagrebox
      let left = event.pageX - pos.left - mask.offsetWidth / 2
      let top = event.pageY - pos.top - mask.offsetHeight / 2
      // 约束范围
      if (left <= -48) {
        left = 0
      }
      let maxX = choose.getBoundingClientRect().width - mask.getBoundingClientRect().width
      if (left - 48 >= maxX) {
        left = maxX
      }
      if (top <= -48) {
        top = 0
      }

      let maxY = choose.getBoundingClientRect().height - mask.getBoundingClientRect().height
      if (top - 48 >= maxY) {
        top = maxY
      }
      mask.style.left = left + 'px'
      mask.style.top = top + 'px'
      large.style.width = (gmtImage.offsetWidth * lagrebox.offsetWidth) / mask.offsetWidth + 'px'
      large.style.left = (-left * large.offsetWidth) / gmtImage.offsetWidth + 'px'
      large.style.top = (-top * large.offsetHeight) / gmtImage.offsetHeight + 'px'
      this.showLabelDebounced(event)
    },
    showLabel(event) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 加载图片到Canvas
      const image = this.$refs.gmtImage
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0, image.width, image.height)

      // 获取点击位置的坐标
      const rect = image.getBoundingClientRect()
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      // 获取点击位置的颜色
      const pixelData = ctx.getImageData(x, y, 1, 1).data
      const color = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`

      this.initLabel(color)
      // 在控制台打印坐标和颜色
      // console.log(`点击位置：(${x}, ${y})`)
      // console.log(`颜色值：${color}`)
    },

    initLabel(val, type) {
      if (val) {
        let isShow = false
        const labelMap = {
          '255, 104, 245': '右冠开口',
          '101, 101, 100': '右冠近段',
          '248, 175, 177': '右冠中段',
          '145, 145, 145': '右冠远段',
          '255, 103, 42': '窦房结支',
          '163, 163, 163': '圆锥支',
          '47, 47, 47': '锐缘支',
          '255, 245, 118': '左室后支',
          '86, 86, 86': '后降支',
          '255, 13, 108': '左主干',
          '50, 177, 108': '前降支开口',
          '255, 98, 38': '前降支近段',
          '254, 220, 254': '前降支中段',
          '252, 2, 5': '前降支远段',
          '255, 5, 59': '第一对角支',
          '36, 237, 48': '第二对角支',
          '255, 123, 161': '第一间隔支',
          '255, 253, 201': '第二间隔支',
          '203, 202, 202': '第三间隔支',
          '0, 73, 134': '回旋支开口',
          '151, 151, 151': '回旋支近段',
          '114, 57, 63': '回旋支中段',
          '255, 113, 55': '回旋支远段',
          '96, 128, 55': '第一钝缘支',
          '255, 243, 96': '第二钝缘支'
        }

        for (const colorCode in labelMap) {
          if (val.includes(colorCode)) {
            this.imgLabel = labelMap[colorCode]
            isShow = true
            break
          }
        }
        this.labelIsShow = isShow
        if (isShow && type === 'gmtClick') {
          // localStorage.setItem('imgLabel', JSON.stringify(this.imgLabel))
          this.$emit('lesionClick', this.imgLabel)
          console.log(this.imgLabel)
        }
      } else {
        this.labelIsShow = false
      }
    },
    getDefaultInfo() {
      this.$api.get(`/v1/webconsole/systemInitValue?module=${this.operation}`).then((res) => {
        if (res.data && res.data.data) {
          this.allInfo = res.data.data
        }
      })
    },
    _dialogSave() {
      this.closeImg()
      this.$emit('refreshData')
    },
    debounce(fn, wait) {
      let timeoutId
      return function () {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
          fn.apply(this, arguments)
        }, wait)
      }
    },
    mouseleave() {
      this.showimgage = false
      this.labelIsShow = false
    },
    handleClickGmtImg(event) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 加载图片到Canvas
      const image = this.$refs.gmtImage
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(image, 0, 0, image.width, image.height)

      // 获取点击位置的坐标
      const rect = image.getBoundingClientRect()
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      // 获取点击位置的颜色
      const pixelData = ctx.getImageData(x, y, 1, 1).data
      const color = `rgb(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]})`
      this.initLabel(color, 'gmtClick')

      // 在控制台打印坐标和颜色
      // console.log(`点击位置：(${x}, ${y})`)
      // console.log(`颜色值：${color}`)
    }
  }
}
</script>
