export default function (dataSet, tag, post_processing = false) {
    // if (tag === 'x00211019') debugger
    if (!dataSet || !dataSet.elements[tag]) return ''
    let vr = dataSet.elements[tag].vr
    let ret = ''
    switch (vr) {
        case 'CS':
        case 'SH':
        case 'LO':
        case 'ST':
        case 'AE':
        case 'UI':
            ret = dataSet.string(tag)
            break
        case 'LT':
        case 'UT':
            ret = dataSet.text(tag)
            break
        case 'PN':
            ret = dataSet.string(tag)?.replaceAll('^', ' ')
            break
        case 'DA':
            ret = dataSet.string(tag)
            if (post_processing) {
                ret
            }
            //date 需后处理
            break
        case 'TM':
            ret = dataSet.string(tag)
            //time 需后处理
            break
        case 'DT':
            ret = dataSet.string(tag)
            //datetime 需后处理
            break
        case 'AS':
            ret = dataSet.string(tag)
            //需后处理
            break
        case 'IS':
            ret = dataSet.intString(tag)
            break
        case 'DS':
            ret = dataSet.floatString(tag)
            break
        case 'SS':
            ret = dataSet.int16(tag)
            break
        case 'US':
            ret = dataSet.uint16(tag)
            break
        case 'SL':
            ret = dataSet.int32(tag)
            break
        case 'UL':
            ret = dataSet.uint32(tag)
            break
        case 'AT':
            ret = dataSet.string(tag)
            //需后处理
            break
        case 'FL':
            ret = dataSet.float(tag)
            break
        case 'FD':
            ret = dataSet.double(tag)
            break
        case 'OB':
            // 其他字节字符串
            // 字节的字符串（“其他”表示没有在VR中定义的内容）
            // 例如 PixelData
            break
        case 'OW':
            ret = dataSet.string(tag)
            //需后处理
            break
        case 'OF':
            ret = dataSet.floatString(tag)
            //需后处理
            break
        case 'SQ':
            ret = dataSet.string(tag)
            //需后处理
            break
        case 'UN':
            ret = dataSet.string(tag)
            //需后处理
            break
    }
    return ret
}