<style scoped lang="less">
/deep/.el-tabs__item:hover {
  color: #409eff;
}

/deep/.el-table__body-wrapper {
  background: #242424;
}

/deep/.el-table .el-table__cell {
  color: #fff;
  background: #242424;
}

/deep/.el-tree {
  background: #242424;
  color: #fff;
}

/deep/ .el-tabs--top.el-tabs--border-card > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--left > .el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right > .el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: none;
}

.diy-wrap {
  position: relative;
}
.diy-tabs {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  .tab-item {
    width: 30px;
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    &:hover {
      color: #409eff;
    }
  }
}

.highlight {
  color: #409eff;
}

.dicom-player {
  // width: 100vw;
  // height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
}
.dicom-player::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.dicom-player::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 10px;
}
.dicom-player::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 10px;
  background-color: #b5c0d0;
}

.patient-info {
  width: 100%;
  height: 3.5rem;
  line-height: 3.5rem;
  background: #242424;
  border-bottom: 1px solid white;
}

.info {
  display: inline;
  font-size: 16px;
  color: #ffffff;
}

.tools-top {
  flex: 0;
}

.dicom-history {
  // height: 55rem;
  width: 300px;
  // flex: 0 0 150px;
  background: #242424;
  border-right: 1px solid white;
  overflow: auto;
  height: 90vh;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.dicom-history::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
  display: none;
}

.dicom-list-panel {
  position: relative;
  flex: 0 150px;
  background: #242424;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dicom-list {
  position: absolute;
  top: 30px;
  bottom: 30px;
  height: calc(100% - 60px);
  width: 100%;
  overflow: hidden;
}

.dicom-list__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.selected {
  border: 3px solid #409eff;
}

.dicom-viewer-panel {
  flex: 1;
  background: #242424;
  position: relative;
}

.image-right-panel {
  overflow: auto;
  flex: 0 300px;
  background: #242424;
}

.scroll-button-panel {
  position: absolute;
  width: 100%;
  height: 30px;
}

.btn-scroll {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-left: 0;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  border-color: #dcdfe6;
  color: #606266;
  appearance: none;
  -webkit-appearance: none;
  background: #bdbcbc;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: 0.1s;
  font-weight: 500;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 20px;
  border-radius: 2px;
}

.btn-scroll:hover {
  background: #918f8f;
}

.report-frames-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.report-frames-list__inner {
  position: relative;
}
</style>
<style lang="less">
.image-right-panel .el-tabs__item {
  font-size: 12px;
  padding: 0px 5px;
  height: 30px;
  line-height: 30px;
  color: #fff;
}

.image-right-panel .el-tabs--bottom .el-tabs__header.is-bottom {
  margin-top: 0;
}

.image-right-panel .el-tabs__content {
  position: relative;
  height: calc(100vh - 105px - 3.5rem);
  /*100vh - 菜单130px？ -25px -30px - 病人信息2rem*/
  width: 100%;
}

#report-frames-tabs .el-tabs__content {
  position: relative;
  height: 100%;
  width: auto;
}

#report-frames-tabs .el-tabs__item {
  padding-left: 10px;
  padding-right: 10px;

  &:nth-child(2) {
    padding-left: 10px;
  }

  &:last-child {
    padding-right: 10px;
  }
}

.el-table {
  font-size: 12px;
}

.el-table .cell {
  white-space: nowrap;
}

.el-table .el-table__cell {
  padding: 0;
}

.el-tab-pane {
  height: 99%;
  overflow: auto;
}

.el-table__body tr.current-row > td.el-table__cell {
  background-color: #409eff;
}

.gmt {
  // height: 200px;
  position: relative;
  margin-left: 80px;
}
.input_has_unit {
  width: 216px;
  margin: 0;
}
</style>

<template>
  <div class="dicom-player">
    <div class="patient-info">
      <div class="info">
        &ensp;当前病人信息:
        <span>
          <span v-if="currentPatient && currentPatient.patientsName">
            &ensp; {{ currentPatient && currentPatient.patientsName }}
          </span>
          <span v-if="currentPatient && currentPatient.patientsSex">
            &ensp;
            {{ currentPatient && currentPatient.patientsSex }}
          </span>
          <span v-if="currentPatient && currentPatient.patientsAge">
            &ensp;
            {{ currentPatient && currentPatient.patientsAge }}
          </span>
          <span v-if="currentPatient && currentPatient.sickArea">
            &ensp;
            {{ currentPatient && currentPatient.sickArea }}
          </span>
        </span>
        <div class="info" style="float: right; margin-left: 10px; margin-right: 10px">
          <!-- {{ this.newData }} -->
          {{ local_time | formateTime }}
        </div>
      </div>
    </div>
    <div class="tools-top">
      <top-tools-bar
        @click="ToolbarClick"
        :selectedDicomViewer="selectedDicomViewer"
        ref="TopToolBar"
      ></top-tools-bar>
    </div>
    <div style="display: flex; flex: 1">
      <div class="dicom-history">
        <dicom-studies
          :selectedDicom="selectedDicom"
          @change="_historyChange"
          @reload="_historyReload"
          @seriesClick="seriesClick"
          @studyClick="studyClick"
          ref="DicomHistoryTree"
          :dicomViewerNum="dicomViewerMax"
          :message="patientsID"
        ></dicom-studies>
        <!-- <dicom-history-tree  @change="_historyChange"
          @reload="_historyReload" ref="DicomHistoryTree" :message="patientsID"></dicom-history-tree> -->
      </div>
      <!-- <div class="dicom-list-panel">
        <div class="scroll-button-panel" style="top: 0">
          <button class="btn-scroll el-icon-arrow-up" @mousedown="_mousedownUp" @mouseup="_mouseup"></button>
        </div>
        <div class="dicom-list" unselectable="on" onselectstart="return false;" onmousedown="return false;"
          ref="DicomList">
          <div class="dicom-list__inner" ref="DicomListInner">
            <dicom-item :class="{
              selected: selectedDicom && selectedDicom.uuid == dicom.uuid,
            }" v-for="(dicom, index) in dicoms" :key="index" :dicom="dicom" @click="seriesClick"></dicom-item>
          </div>
        </div>
        <div class="scroll-button-panel" style="bottom: 0">
          <button class="btn-scroll el-icon-arrow-down" @mousedown="_mousedownDown" @mouseup="_mouseup"></button>
        </div>
      </div> -->
      <div class="dicom-viewer-panel" id="dicom-viewer-panel">
        <!-- <GmtImgBox :isShow="showCoronaryImg" @refreshData="refreshData"></GmtImgBox> -->
        <dicom-viewer
          v-for="index in dicomViewerMax"
          :key="index"
          ref="DicomViewer"
          @dicomViewClick="DicomViewerClick"
          @measurementCompleted="measurementCompleted"
          @changeImage="changeImageFromDicomViewer"
          :dicomViewerNum="dicomViewerMax"
          :isRefresh="isRefresh"
          :style="`width:calc(100% / ${columns});height:calc(100% / ${rows})`"
          :class="{ selected: selectedDicomViewer == this }"
          :measurementsList="measurementsList"
          :tagList="tagList"
          :targetGroup="targetGroup"
        ></dicom-viewer>
      </div>
      <div class="image-right-panel">
        <el-tabs tab-position="bottom" style="height: 100%" v-model="selectedTab">
          <el-tab-pane label="标注信息" name="Measurements">
            <measurements-list
              :measurementsList="measurementsList"
              :imgs="selectedSeriesImgs"
              :itemKey="itemKey"
              :targets="currentTargets"
              @deleteMeasurement="queryTargetsMeasurementsByOperation"
              @imgUpload="queryReportImages"
            ></measurements-list>
          </el-tab-pane>
          <el-tab-pane
            v-if="this.$route.query.operationId"
            label="报告影像"
            name="Report Frames"
            class="div-wrap"
          >
            <div class="diy-tabs">
              <div class="tab-item" @click="tabClick('all')">
                <span
                  :class="{ highlight: examineNameList.findIndex((item) => item.checked) == -1 }"
                >
                  全部
                </span>
              </div>
              <div
                class="tab-item"
                v-for="(item, index) in examineNameList"
                :key="index"
                @click="tabClick(index)"
              >
                <span :class="{ highlight: item.checked }">{{ item.name | getSimpleInfo }}</span>
              </div>
            </div>
            <report-image-list
              v-if="this.$route.query.operationId"
              :imgs="reportImages"
              @deletedImgFromReport="queryReportImages"
            ></report-image-list>

            <!-- <el-tabs
              class="inner-tabs"
              v-model="selectedExamineNameFromReport"
              type="card"
              tab-position="right"
            >
              <el-tab-pane
                v-for="(item, index) in examineNameList"
                :key="index"
                :label="item"
                :name="item"
              >
                <div>
                  <report-image-list
                    :imgs="reportImages"
                  ></report-image-list>
                </div>
              </el-tab-pane>
            </el-tabs> -->
            <!-- <el-tabs
              id="report-frames-tabs"
              tab-position="right"
              style="height: 100%; width: auto"
              v-model="selectedLession"
            >
              <el-tab-pane
                v-for="lession in Object.keys(reportFrames)"
                :key="lession"
                :label="lession"
                :name="lession"
              >
                <div class="report-frames-list">
                  <div
                    class="report-frames-list__inner"
                    v-for="(frame, index) in reportFrames[lession]"
                    :key="index"
                  >
                    <button
                      style="
                        position: absolute;
                        right: 0;
                        top: 0;
                        color: white;
                        background: gray;
                        border-radius: 50%;
                      "
                      @click="_frameDelete(frame, reportFrames[lession])"
                    >
                      x
                    </button>
                    <img :src="frame" alt="缩略图" style="width: 100px; height: 100px" />
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs> -->
          </el-tab-pane>
          <el-tab-pane label="病人信息" name="Patient information">
            <el-table
              :data="defaultPatientInformation"
              border
              :highlight-current-row="true"
              :fit="true"
              height="100%"
            >
              <el-table-column prop="description" label="" width="100px"></el-table-column>
              <el-table-column label="" :resizable="true" min-width="199px">
                <template slot-scope="scope">
                  <span>{{ getItemText(scope.row.tag) }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="所有标签" name="All Tags">
            <all-tags-list
              :dataSet="
                selectedDicomViewer && selectedDicomViewer.image && selectedDicomViewer.image.data
              "
            ></all-tags-list>
          </el-tab-pane>
          <!-- <el-tab-pane label="影像保存记录" name="imgTraces">

          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <footer-bar v-model="selectedDicomViewer"></footer-bar>

    <!-- 对话框 -->
    <el-dialog :title="imgToReportDialogFormTitle" :visible.sync="imgToReportDialogVisible">
      <el-form :model="imgToReportDialogForm" ref="imgToReportDialogForm">
        <el-form-item label="检查名称:">
          <el-select v-model="imgToReportDialogForm.selectedExamineName" placeholder="请选择">
            <el-option
              v-for="(item, key) in examineNameList"
              :key="key"
              :label="item.name"
              :value="item.value"
            ></el-option>
            <!-- <el-option label="CAG" value="CAG"></el-option>
            <el-option label="PCI" value="PCI"></el-option>
            <el-option label="XA" value="XA"></el-option>
            <el-option label="FFR" value="FFR"></el-option>
            <el-option label="IVUS" value="IVUS"></el-option>
            <el-option label="OCT" value="OCT"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="病变部位:">
          <el-input
            class="input_has_unit"
            placeholder="请输入"
            v-model="imgToReportDialogForm.selectedLesionName[0]"
          ></el-input>
          <!-- <el-tag
            style="margin: 5px"
            :key="tag"
            v-for="tag in imgToReportDialogForm.selectedLesionName"
            closable
            :disable-transitions="false"
            @close="handleLesionClose(tag)"
          >
            {{ tag }}
          </el-tag> -->
        </el-form-item>
      </el-form>
      <div class="gmt">
        <el-radio-group style="margin-bottom: 25px" v-model="templateImg">
          <el-radio :label="1">心脏冠状动脉</el-radio>
        </el-radio-group>
        <GmtImgBox v-if="templateImg === 1" @lesionClick="lesionClick"></GmtImgBox>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="saveImgToReportCancel">取 消</el-button>
        <el-button type="primary" @click="saveImgToReport">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var parseDate = function (datetime) {
  return datetime < 10 ? '0' + datetime : datetime
}
import { mapState, mapMutations } from 'vuex'
import readTagValueFromDataSet from '../../../assets/js/readTagValueFromDataSet'
// import DicomHistoryTree from './components/DicomHistoryTree.vue'
// import DicomItem from './components/DicomItem.vue'
import TopToolsBar from './components/TopToolsBar.vue'
import FooterBar from './components/FooterBar.vue'
import DicomViewer from './components/DicomViewer.vue'
import AllTagsList from './components/AllTagsList.vue'
import MeasurementsList from './components/MeasurementsList.vue'
import DicomStudies from './components/DicomStudies.vue'
import GmtImgBox from './components/GmtImgBox'
import ReportImageList from './components/ReportImageList.vue'

import { createNewImage } from '../../../assets/js/imageUtils'
import { downloadImage, downloadDCM } from '../../../assets/js/imageUtils'
import { formatFetchMeasurements } from '../../../utils/format'
import { PDF_HOST } from '../../../utils/globalVariable'
export default {
  name: 'DicomPlayer',
  components: {
    // DicomHistoryTree,
    // DicomItem,
    TopToolsBar,
    FooterBar,
    DicomViewer,
    AllTagsList,
    MeasurementsList,
    DicomStudies,
    GmtImgBox,
    ReportImageList
  },
  props: {},
  data() {
    return {
      menuItem: '',
      newData: '',
      currentTime: '',
      yMd: '',
      times: '',
      isRefresh: 'noRefresh',
      local_time: new Date(),
      selectedTab: 'Report Frames',
      selectedLession: '冠脉造影',
      // 指标模版
      targetGroup: [],
      // 当前选中的序列
      selectedDicom: null,
      // 当前选中的视图框
      selectedDicomViewer: null,
      // 当前选中的study的studyInstanceUid
      currentStudyInstanceUid: null,
      // 当前右侧展示的指标测量记录，指标树 + 测量记录 + 图片
      currentTargets: [],
      // 当前选中series的保存过的图片的列表
      selectedSeriesImgs: [],
      // allmeasurement的table的itemkey,刷新table视图用
      itemKey: 0,
      // 当前选中的series的保存过的所有图片的测量记录（指标）
      selectedSeriesImgTargets: [],
      // 将图片添加到报告的对话框
      imgToReportDialogVisible: false,
      imgToReportDialogForm: {
        selectedExamineName: '',
        selectedLesionName: [],
        type: 'saveReport'
      },
      imgToReportDialogFormTitle: '添加影像到报告',
      // 在右侧报告影响中选中的检查名
      selectedExamineNameFromReport: 'CAG',
      examineNameList: [
        {
          checked: false,
          name: 'DSA',
          value: 'DSA'
        },
        // {
        //   checked: false,
        //   name: 'PCI',
        //   value: 'PCI'
        // },
        {
          checked: false,
          name: 'CTA',
          value: 'CTA'
        },
        {
          checked: false,
          name: 'MRA',
          value: 'MRA'
        },
        // {
        //   checked: false,
        //   name: 'XA',
        //   value: 'XA'
        // },
        // {
        //   checked: false,
        //   name: '超声',
        //   value: 'US'
        // },
        {
          checked: false,
          name: 'FF',
          value: 'FF'
        },
        {
          checked: false,
          name: 'IMR',
          value: 'IMR'
        },
        {
          checked: false,
          name: 'CTP',
          value: 'CTP'
        },
        {
          checked: false,
          name: 'MRP',
          value: 'MRP'
        },
        {
          checked: false,
          name: 'IVUS',
          value: 'IVUS'
        },
        {
          checked: false,
          name: 'OCT',
          value: 'OCT'
        }
      ],
      // 添加到报告中的影像
      reportImages: [],
      reportFrames: {},
      dicoms: [],
      rows: 1,
      columns: 1,
      tagList: {
        lt: ['x00100010', 'x00100040', 'x00100020', 'x00100030', 'x00080060'],
        rt: [],
        rb: [],
        lb: ['x0008103e']
      },
      defaultPatientInformation: [
        {
          tag: 'x00100010',
          description: 'Patient Name'
        },
        {
          tag: 'x00100020',
          description: 'Patient ID'
        },
        {
          tag: 'x00100030',
          description: 'Patient Birth Date'
        },
        {
          tag: 'x00100040',
          description: 'Patient Sex'
        },
        {
          tag: 'x00101010',
          description: 'Patient Age'
        },
        {
          tag: 'x00101030',
          description: 'Patient Weight'
        },
        {
          tag: '',
          description: 'Patient Address'
        },

        {
          tag: 'x00080020',
          description: 'Study Date'
        },
        {
          tag: 'x00080030',
          description: 'Study Time'
        },
        {
          tag: 'x00080050',
          description: 'Study ID'
        },
        {
          tag: 'x00080060',
          description: 'Study Modality'
        },
        {
          tag: 'x00081030',
          description: 'Study Description'
        },
        {
          tag: 'x00080021',
          description: 'Series Date'
        },
        {
          tag: 'x00080031',
          description: 'Series Time'
        },
        {
          tag: 'x0008103e',
          description: 'Series Description'
        }
      ],
      listScrollTimerId: null,
      val_list: [],
      patientsID: '',
      measurementsList: [],
      measurementCache: {},
      showCoronaryImg: true,
      templateImg: ''
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    }),
    dicomViewerMax: function () {
      return this.rows * this.columns
    }
  },
  watch: {
    currentPatient: {
      handler(val) {
        if (val) {
          document.title = '介入影像'

          this.currentPatient.patientsName &&
            (document.title += `-${this.currentPatient.patientsName}`)
          this.currentPatient.patientsSex &&
            (document.title += `-${this.currentPatient.patientsSex}`)
          this.currentPatient.patientsAge &&
            (document.title += `-${this.currentPatient.patientsAge}`)

          this.patientsID = this.currentPatient.patientsID
          // this.getFrames()
        }
      }
    },
    // selectedLession: {
    //   handler(val) {
    //     if (val) {
    //       if (val.includes('冠脉造影')) {
    //         this.showCoronaryImg = true
    //       } else {
    //         this.showCoronaryImg = false
    //       }
    //     }
    //   },
    //   immediate: true,
    // },
    selectedDicomViewer: {
      handler(newVal, oldVal) {
        if (oldVal && newVal._uid !== oldVal._uid) {
          // let toolList = ['EllipticalRoi', 'Length', 'Angle', 'FreehandRoi', 'ArrowAnnotate']
          // let cornerstoneTools = this.$cornerstoneTools
          // toolList.forEach(item => {
          //   let flag = cornerstoneTools.isToolActiveForElement(newVal.elements[0], item)
          //   if (flag) {
          //     console.log(`${item}激活的, setPassive`);
          //     cornerstoneTools.setToolPassive(item)
          //   }
          // })
          // this.queryMeasurementsBySeries()
        }
      },
      immediate: true
    },
    dicomViewerMax: {
      handler() {
        // console.log('newVal:',newVal, 'oldVal:', oldVal );
        // console.log('dicomViewer', this.$refs.DicomViewer);
        // if (oldVal > newVal && newVal === 1) {
        //   this.DicomViewerClick(this.$refs.DicomViewer[0])
        // }
        if (this.$refs.DicomViewer[0].dicom) {
          this.DicomViewerClick(this.$refs.DicomViewer[0])
        }
      }
    }
  },
  created() {
    var code = localStorage.getItem('userCode')
    if (code == undefined) {
      var url = `http://${location.host}`
      url += '/#/'
      alert('请先登录')
      window.open(url, '_self')
    }
    var that = this
    this.timer = setInterval(function () {
      that.local_time = new Date()
    }, 1000)
  },
  mounted() {
    // 获取指标
    this.getTargetGroup()
    // 获取添加到报告的影像
    this.queryReportImages()
    // 获取当前手术下的保存过的图像 砍了
    // this.queryMeasurementsByOperation()
    // 获取当前手术下保存过的指标及图像
    this.queryTargetsMeasurementsByOperation()
    this.DicomViewerClick(this.$refs.DicomViewer[0])
    let self = this
    window.onresize = function () {
      let dicomImage = [...document.getElementsByClassName('dicom-image')]
      dicomImage.map((e) => {
        self.$cornerstone.resize(e, true)
      })
    }

    // var id = location.hash.substr(location.hash.indexOf("=") + 1);
    // let menuItem = JSON.parse(localStorage.getItem(id));
    // 先注释了获取右键选项的
    // this.getMenu()
    // let url = `/v1/webconsole/study/operation/get/${this.$route.query.operationId}`
    // if (this.$route.query.operationId) {
    //   this.axios.get(url).then((res) => {
    //     document.title =
    //       '介入影像' +
    //       '-' +
    //       `${res.data.data.patientName || res.data.data.patientsName}-${res.data.data.patientsSex}-${res.data.data.patientsAge}`
    //     this.patientsID = res.data.data.patientsID
    //   })
    // }

    // this.getMeasurements()
  },
  filters: {
    formateTime: function (val) {
      var date = new Date(val)
      var year = date.getFullYear()
      var month = parseDate(date.getMonth() + 1)
      var day = parseDate(date.getDate())
      var hours = parseDate(date.getHours())
      var min = parseDate(date.getMinutes())
      var sec = parseDate(date.getSeconds())
      return year + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec + ' '
    },
    getSimpleInfo(val) {
      if (val.includes('(') && val.includes(')')) {
        return /\(([^)]+)\)/.exec(val)[1]
      } else {
        return val
      }
    }
  },
  beforeDestroy() {
    this.$bus.$off('dicomLoaded')
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    // 点击tab
    tabClick(index) {
      if (index === 'all') {
        this.examineNameList.forEach((item, index, arr) => {
          arr[index].checked = false
        })
        this.$api
          .post(`/v1/webconsole/pacs/report/operation/${this.$route.query.operationId}`)
          .then(({ data }) => {
            // console.log(data);
            this.reportImages = data.data
          })
          .catch((err) => {
            console.log(err)
          })
        return
      }

      this.examineNameList.forEach((item, index, arr) => {
        arr[index].checked = false
      })
      this.examineNameList[index].checked = true

      this.queryReportImages()
    },
    // 等待image渲染后，再渲染跳转测量记录
    renderMeasurements() {
      // TODO
    },

    // 获取指标模版
    getTargetGroup() {
      this.$api
        .post('/v1/webconsole/pacs/pubexamine/groups')
        .then(({ data }) => {
          // this.targetGroup = data.data
          let list = data.data
          list.forEach((item, i, arr) => {
            arr[i].label = item.groupName
            arr[i].value = item.id
            this.$api.post(`/v1/webconsole/pacs/pubexamine/group/${item.id}`).then(({ data }) => {
              arr[i].children = data.data
              arr[i].children.forEach((ele, index, a) => {
                a[index].label = ele.itemTitle
                a[index].value = ele.id
              })
            })
          })
          this.targetGroup = list
          // console.log(list);
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 通过dicomViewer.vue发出的修改图像事件
    changeImageFromDicomViewer(dicom) {
      // console.log(dicom);
      this.selectedDicom = dicom
    },
    // 测量完成
    // 不需要
    measurementCompleted(data) {
      // console.log(data);
      // this.measurementsList.push(data)
    },

    // ====================================================
    // ================ DicomStudies ======================
    // ====================================================

    // 点击study
    studyClick(msg) {
      this.currentStudyInstanceUid = msg.studyInstanceUid
      // console.log(msg.visible);
      if (msg.visible && !this.$route.query.operationId) {
        // 获取当前study的测量指标
        this.queryTargetsMeasurementsByOperation('study')
      }
    },
    // 点击左侧的一个 series
    // series 本地： 一个dicom文件
    // series 服务器： 一个series 相关tag信息
    seriesClick(series) {
      // console.log('series', series);
      // console.log(this.$cornerstone);
      // console.log(this.$cornerstoneTools);
      // 删除dicom视图测量记录
      // let toolState =
      //   this.$cornerstoneTools.globalImageIdSpecificToolStateManager.toolState
      // Object.keys(toolState).forEach((key) => {
      //   if (key.includes(series.fileName)) {
      //     delete toolState[key]
      //   }
      // })
      console.log(series, 'series')
      if (series.imageAmount > 800) {
        alert('文件过大,暂不支持播放!')
        return
      }
      this.selectedDicom = series
      if (!series.isLoaded) {
        const loading = this.$loading({
          target: '#dicom-viewer-panel',
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$bus.$on('dicomLoaded', (e) => {
          // 本地dicom
          if (e instanceof File) {
            this.selectedDicomViewer?.setDicom(series)
          }
          // 服务器dicom
          if (e === series.seriesInstanceUid) {
            this.selectedDicomViewer?.setDicom(series)
          }
          // this.$bus.$off('dicomLoaded')
          loading.close()
        })
        setTimeout(() => {
          loading.close()
        }, 60000)
      } else {
        this.selectedDicomViewer?.setDicom(series)
      }

      this.$refs.TopToolBar.refreshStatus()
      // 获取当前dicom的测量列表
      // this.queryMeasurementsBySeries();
    },

    // ====================================================
    // ================ 添加到报告中的影像 ====================
    // ====================================================

    // 获取报告影像
    queryReportImages() {
      let formData = new FormData()
      let checkedExamineName = this.examineNameList.filter((item) => item.checked)
      if (checkedExamineName.length > 0) {
        if (checkedExamineName[0].value == 'all') {
          formData = {}
        } else {
          formData.append('examineName', checkedExamineName[0].value)
        }
      }
      this.$api
        .post(`/v1/webconsole/pacs/report/operation/${this.$route.query.operationId}`, formData)
        .then(({ data }) => {
          // console.log(data);
          this.reportImages = data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // ====================================================
    // ================ Index主菜单 ========================
    // ====================================================

    // 主菜单点击事件
    ToolbarClick(type) {
      // console.log(type);
      if (!type) return
      if (type === 'play') {
        this.selectedDicomViewer?.play()
      } else if (type === 'pause') {
        this.selectedDicomViewer?.pause()
      } else if (type === 'stop') {
        this.selectedDicomViewer?.stop()
      } else if (type === 'reverse') {
        this.selectedDicomViewer?.reversePlay()
      } else if (type === 'first') {
        this.selectedDicomViewer?.first()
      } else if (type === 'pre') {
        this.selectedDicomViewer?.pre()
      } else if (type === 'next') {
        this.selectedDicomViewer?.next()
      } else if (type === 'last') {
        this.selectedDicomViewer?.last()
      } else if (type === 'loop') {
        this.selectedDicomViewer?.loop()
      } else if (type === 'upward') {
        for (let i = 0; i < this.dicoms.length; i++) {
          if (this.dicoms[i].uuid === this.selectedDicom.uuid && i != 0) {
            this.seriesClick(this.dicoms[i - 1])
          }
        }
      } else if (type === 'down') {
        for (let i = this.dicoms.length - 1; i >= 0; i--) {
          if (this.dicoms[i].uuid === this.selectedDicom.uuid && i != this.dicoms.length - 1) {
            this.seriesClick(this.dicoms[i + 1])
          }
        }
      } else if (type === 'camera') {
        // 截屏保存
        this.imgToReportDialogFormTitle = '捕获影像'
        this.imgUploadToReport('saveImage')
        // this.$cornerstoneTools.SaveAs(
        //   this.selectedDicomViewer.elements[0],
        //   this.currentPatient.patientName +
        //     '_' +
        //     this.selectedDicomViewer.dicom.filePath.split('/').pop() +
        //     '_' +
        //     this.selectedDicomViewer.frameIndex
        // )
      } else if (type === 'addToReport') {
        // 添加到手术报告
        this.imgToReportDialogFormTitle = '添加影像到报告'
        this.imgUploadToReport('saveReport')
      } else if (type === 'info') {
        this.selectedTab = 'All Tags'
      } else if (type === 'target') {
        this.selectedTab = 'Measurements'
      } else if (type === 'imgTraces') {
        this.selectedTab = 'imgTraces'
      } else if (type === 'img-report') {
        this.selectedTab = 'Report Frames'
      } else if (type.includes('layout')) {
        // console.log('layout');
        let [row, col] = type.split('-')[1].split('x')
        this.rows = row
        this.columns = col
        this.$nextTick(function () {
          if (document.createEvent) {
            var event = document.createEvent('HTMLEvents')
            event.initEvent('resize', true, true)
            window.dispatchEvent(event)
          } else if (document.createEventObject) {
            window.fireEvent('onresize')
          }
        })
      }
      // 主菜单--文件
      else if (type === 'openLocalFile') {
        let inputDom = document.createElement('input')
        inputDom.type = 'file'
        inputDom.multiple = 'multiple'
        let self = this
        inputDom.onchange = () => {
          if (inputDom.files) {
            for (let i = 0; i < inputDom.files.length; i++) {
              self.dicoms.push(inputDom.files[i])
            }
            console.log('self.dicoms:', self.dicoms)
            // 解析本地dicom文件 测试用
            console.log('self.dicomstudy:', self.$refs.DicomHistoryTree.list)
            self.$refs.DicomHistoryTree.list.forEach((item, i, arr) => {
              if (item.type && item.type === 'local') {
                arr[i].children.push(self.dicoms[self.dicoms.length - 1])
              }
            })
            // let imageIds = []
            // const imageId = this.$cornerstoneWADOImageLoader.wadouri.fileManager.add(self.dicoms[0])
            // console.log('imageId:', imageId);
            // imageIds.push(imageId)
            // try {
            //     self.$cornerstone.loadAndCacheImage(imageIds[0]).then(
            //       function (image) {
            //         let viewport = self.$cornerstone.getDefaultViewportForImage(self.$refs.DicomViewer[0].$refs.DicomImage, image)
            //         self.$cornerstone.displayImage(self.$refs.DicomViewer[0].$refs.DicomImage, image, viewport)
            //         // self.image = image
            //       },
            //       function (err) {
            //         alert('DicomPlay--seriesClick:' + err)
            //         console.log(err);
            //       }
            //     )
            //   } catch (err) {
            //     alert('DicomPlay--seriesClick--err:' + err)
            // }
          } else {
            console.log('未选择文件')
          }
        }

        // this.selectedDicomViewer?.setDicom(self.dicoms[0])
        document.body.append(inputDom)
        inputDom.click()
        document.body.removeChild(inputDom)
      }
      // 主菜单--导出
      else if (type === 'exportImage') {
        if (this.selectedDicomViewer) {
          downloadImage(
            this.selectedDicomViewer.image,
            this.currentPatient.patientsName +
              '_' +
              this.selectedDicomViewer.dicom.filePath.split('/').pop() +
              '_' +
              this.selectedDicomViewer.frameIndex
          )
        }
      } else if (type === 'exportDICOM') {
        if (this.selectedDicomViewer) {
          downloadDCM(this.selectedDicomViewer.dicom)
        }
      } else if (type === 'download') {
        console.log('self.dicomstudy:', this.selectedDicomViewer.dicom)
        // return
        if (this.selectedDicomViewer.dicom) {
          let dicomVal = this.selectedDicomViewer.dicom
          this.$api
            .post(
              `/v1/webconsole/pacs/patient/series/video/${
                this.$route.query.patientsID || this.$route.query.operationId
              }/${dicomVal.studyInstanceUid}/${dicomVal.seriesInstanceUid}/${
                dicomVal.sopInstanceUid
              }`
            )
            .then((res) => {
              if (res.data.status == 200 && res.data.data) {
                window.open(
                  PDF_HOST + `/api/v1/webconsole/downloadcenter/download${res.data.data}`,
                  '_blank'
                )
              }
            })
        } else {
          this.$message({
            message: '请先选择要下载的PACS影像',
            type: 'warning'
          })
        }
      }
    },
    // 主菜单-保存截图
    async captureImage(params) {
      this.imgToReportDialogVisible = false
      const canvas = this.selectedDicomViewer.$refs.DicomImage.querySelector('canvas')
      const image = this.selectedDicomViewer.image
      const element = this.selectedDicomViewer.$refs.DicomImage
      // for (let i of this.selectedDicomViewer.cacheSeriesMeasurements) {
      //   i.itemValue = parseFloat(i.itemValue)
      // }

      let pacsPrint = {
        operationId: this.$route.query.operationId,
        examineName: params.selectedExamineName || '',
        lesionName: params.selectedLesionName[0] || '', // 这里是只有单个病变部位
        imageBase64: createNewImage(canvas, image, element),
        studyInstanceUID: this.currentStudyInstanceUid,
        seriesInstanceUID: this.selectedDicom.seriesInstanceUid,
        items: this.selectedDicomViewer.cacheSeriesMeasurements.filter(
          (item) => item.imageId === image.imageId
        ),
        businessType: 'OPERATION', // OPERATION
        businessId: this.$route.query.operationId,
        studyTime: this.selectedDicom?.studyTime
      }

      if (!this.$route.query.operationId && this.$route.query.patientsID) {
        pacsPrint = {
          ...pacsPrint,
          businessType: 'PATIENT',
          businessId: this.currentPatient.patientsIndex
        }
      }

      // console.log(pacsPrint);
      return new Promise((resolve, reject) => {
        this.$api
          .post(`/v1/webconsole/pacs/measure/save`, pacsPrint)
          .then(({ data }) => {
            // console.log(data);
            if (data.status === 200) {
              resolve(data.data.id)
              this.$message.success('保存成功')
              // 初始化缓存的测量标注
              this.selectedDicomViewer.cacheSeriesMeasurements =
                this.selectedDicomViewer.cacheSeriesMeasurements.filter((item) => {
                  if (item.imageId !== image.imageId) {
                    return true
                  } else {
                    this.selectedDicomViewer.deleteAnnotationByUuid(item.uuid)
                    this.$cornerstone.updateImage(element)
                    return false
                  }
                })

              // this.queryMeasurementsBySeries();
              if (!this.$route.query.operationId) {
                // 获取当前study的测量指标
                this.queryTargetsMeasurementsByOperation('study')
              } else {
                this.queryTargetsMeasurementsByOperation()
              }
            }
          })
          .catch((err) => {
            console.log('保存图像 报错', err)
            reject(err)
          })
      })
    },
    // 主菜单-添加到手术报告
    async saveToReport(params) {
      // this.imgToReportDialogVisible = false
      if (this.isMeasuredOnImage()) {
        // 有测量
        let imageId = await this.captureImage(params)
        this.$api
          .post(
            `/v1/webconsole/pacs/report/operation/add_measure/${imageId}/${this.$route.query.operationId}`
          )
          .then(({ data }) => {
            // console.log(data);
            if (data.status === 200) this.$message.success(data.msg)
            this.queryReportImages()
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        // 没测量
        let formData = new FormData()
        const canvas = this.selectedDicomViewer.$refs.DicomImage.querySelector('canvas')
        const image = this.selectedDicomViewer.image
        const element = this.selectedDicomViewer.$refs.DicomImage
        formData.append('examineName', params.selectedExamineName)
        formData.append('lesionName', params.selectedLesionName[0])
        formData.append('imageBase64', createNewImage(canvas, image, element))
        this.$api
          .post(
            `/v1/webconsole/pacs/report/operation/add_dicom/${this.$route.query.operationId}`,
            formData
          )
          .then(({ data }) => {
            // console.log('纯图片添加到报告', res);
            if (data.status === 200) {
              this.$message.success(data.msg)
            }
            this.imgToReportDialogVisible = false
            this.queryReportImages()
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 选中当前 视图块
    DicomViewerClick(viewer) {
      // console.log('当前viewer:', viewer);
      this.$cornerstone.updateImage(viewer.elements[0])
      // console.log('原本的viewer:', this.selectedDicomViewer);
      if (this.selectedDicomViewer) {
        // 初始化 原视图块
        this.selectedDicomViewer.isMeasurementCompleted = true
        this.selectedDicomViewer.firstSelected = true
        this.selectedDicomViewer.isSelected = false
      }
      this.selectedDicomViewer = viewer
      this.selectedDicomViewer.isSelected = true
      if (viewer.dicom) {
        this.selectedDicom = viewer.dicom
      }
      if (this.selectedDicomViewer.dicom === null) {
        return
      } else {
        // 点击dicom播放
        // if (this.selectedDicomViewer.dicom.flag) {
        //   this.selectedDicomViewer?.play()
        // } else {
        //   this.selectedDicomViewer?.pause()
        // }
        this.selectedDicomViewer.dicom.flag = !this.selectedDicomViewer.dicom.flag
      }

      // this.queryMeasurements()
      // this.queryMeasurementsBySeries()
    },
    // ====================================================
    // ================ MeasurementsList ==================
    // ====================================================

    // 获取当前手术下的历史保存过的图片和对应测量过的指标（未加入报告）
    queryMeasurementsByOperation() {
      this.$api
        .post(`/v1/webconsole/pacs/measure/operation/${this.$route.query.operationId}`)
        .then(({ data }) => {
          // console.log('手术下的图片', data);
          this.selectedSeriesImgs = data.data
          this.queryMeasurementsBySeriesImage(data.data)
        })
        .catch((err) => {
          alert('index-query operation measurements-error', err)
        })
    },

    // 获取当前手术下的历史保存过的指标
    queryTargetsMeasurementsByOperation(type = 'operation') {
      if (type === 'operation') {
        this.$api
          .post(
            `/v1/webconsole/pacs/operation/measure/group_items/${this.$route.query.operationId}`
          )
          .then(({ data }) => {
            // console.log('手术下的所有指标', data);
            // this.selectedSeriesImgs = data.data
            this.currentTargets = data.data
            this.itemKey = Math.random()
          })
          .catch((err) => {
            alert('DicomPlayer-index-query operation targets measurements-error', err)
          })
      } else if (type === 'study') {
        this.$api
          .post(`/v1/webconsole/pacs/study/measure/group_items/${this.currentStudyInstanceUid}`)
          .then(({ data }) => {
            // console.log('study下的指标', data);
            // this.selectedSeriesImgs = data.data
            this.currentTargets = data.data
            this.itemKey = Math.random()
          })
          .catch((err) => {
            alert('DicomPlayer-index-query study targets measurements-error', err)
          })
      }
    },

    // 获取当前series下的历史保存过的图片和图片对应的测量过的指标（未加入报告）
    // 已砍
    queryMeasurementsBySeries() {
      // 本地删除当前image下的测量记录
      // let toolState = this.$cornerstoneTools.globalImageIdSpecificToolStateManager.toolState
      // console.log(toolState, this.selectedDicom);
      // Object.keys(toolState).forEach((key) => {
      //   if (key.includes(this.selectedDicom.filePath)) {
      //     delete toolState[key]
      //   }
      // })
      // 获取当前series下保存的图像及测量记录
      let studyInstanceUid = this.currentStudyInstanceUid
      let seriesInstanceUid = this.selectedDicom.seriesInstanceUid
      this.$api
        .post(`/v1/webconsole/pacs/measure/study/${studyInstanceUid}/${seriesInstanceUid}`)
        .then(({ data }) => {
          // console.log('当前series的测量记录:',data);
          this.selectedSeriesImgs = data.data
          this.queryMeasurementsBySeriesImage(data.data)
        })
        .catch((err) => {
          alert('index-query series measurements-error', err)
        })
    },
    // 获取当前series的暂存图像的指标测量记录
    async queryMeasurementsBySeriesImage(params) {
      let requests = []
      for (let i of params) {
        let request = this.$api.post(`/v1/webconsole/pacs/measure/group_items/${i.id}`)
        requests.push(request)
      }
      try {
        const responses = await Promise.all(requests)
        responses.forEach((response) => {
          // console.log('response', response);
          let list = []
          response.data.data.forEach((i) => {
            list = i.items
          })
          // console.log(list);
          let requestUrlSplits = response.config.url.split('/')
          let imageId = requestUrlSplits[requestUrlSplits.length - 1]
          let index = this.selectedSeriesImgs.findIndex((item) => item.id === imageId)
          if (index != -1) {
            this.selectedSeriesImgs[index].targets = []
            this.selectedSeriesImgs[index].targets = list
          }
        })
        let copy = JSON.parse(JSON.stringify(this.selectedSeriesImgs))
        this.selectedSeriesImgs = null
        this.selectedSeriesImgs = copy
        this.itemKey = Math.random()
      } catch (err) {
        console.log('Error', err)
      }
    },
    // 右侧主菜单将以保存的图片添加到报告中
    imgUploadToReport(type) {
      this.imgToReportDialogForm.type = type

      // 检索当前image是否进行了测量
      if (type === 'saveImage') {
        let toolState = this.$cornerstoneTools.globalImageIdSpecificToolStateManager.toolState
        // console.log(toolState, this.selectedDicomViewer.image);
        let flag = false
        Object.keys(toolState).forEach((key) => {
          if (key.includes(this.selectedDicomViewer.image.imageId)) {
            // 说明当前image中有测量记录
            flag = true
          }
        })
        if (!flag) {
          this.$message.warning('当前影像还未测量！')
          return
        }
      }
      // 初始化表单数据
      this.imgToReportDialogForm.selectedExamineName = ''
      this.imgToReportDialogForm.selectedLesionName = []

      this.imgToReportDialogVisible = true
    },

    // ====================================================
    // ================ DialogForm ========================
    // ====================================================

    // 判断当前image中是否进行了测量
    isMeasuredOnImage() {
      let toolState = this.$cornerstoneTools.globalImageIdSpecificToolStateManager.toolState
      // console.log(toolState, this.selectedDicomViewer.image);
      let flag = false
      Object.keys(toolState).forEach((key) => {
        if (key.includes(this.selectedDicomViewer.image.imageId)) {
          // 说明当前image中有测量记录
          flag = true
        }
      })
      return flag
    },
    // 确认将图片添加到报告中
    saveImgToReport() {
      const params = this.imgToReportDialogForm
      console.log(params)
      if (params.type === 'saveImage') {
        this.captureImage(params)
      } else if (params.type === 'saveReport') {
        this.saveToReport(params)
      }
    },
    // 取消当前测量记录
    saveImgToReportCancel() {
      this.imgToReportDialogVisible = false
    },

    // ====================================================
    // ================ GmtImgBox =========================
    // ====================================================

    // 获取病变部位
    lesionClick(e) {
      // console.log(e)
      // let index = this.imgToReportDialogForm.selectedLesionName.findIndex((item) => item === e)
      // if (index != -1) return
      // this.imgToReportDialogForm.selectedLesionName.push(e)
      // 改成单选
      this.imgToReportDialogForm.selectedLesionName = [e]
    },
    // 删除已经选中的病变部位tag
    handleLesionClose(tag) {
      let index = this.imgToReportDialogForm.selectedLesionName.findIndex((item) => item === tag)
      if (index != -1) this.imgToReportDialogForm.selectedLesionName.splice(index, 1)
    },

    // ===================== 已废弃 =======================
    refreshData() {
      this.getMenu()
      this.getFrames()
      this.isRefresh = 'isRefresh'
      setTimeout(() => {
        this.isRefresh = 'noRefresh'
      }, 500)
    },
    getItemText(tag) {
      if (this.selectedDicomViewer && this.selectedDicomViewer.image) {
        let dataSet = this.selectedDicomViewer.image.data
        return readTagValueFromDataSet(dataSet, tag, true)
      }
      return ''
    },
    _scrollList(dir) {
      let self = this
      let step = 10 //每次滚动长度
      if (self.listScrollTimerId) {
        clearInterval(self.listScrollTimerId)
        self.listScrollTimerId = null
      }
      self.listScrollTimerId = setInterval(() => {
        let scrollIndex = self.$refs.DicomList.scrollTop
        scrollIndex += step * dir
        self.$refs.DicomList.scrollTop = scrollIndex
      }, 20)
    },
    _mouseup() {
      if (this.listScrollTimerId) {
        clearInterval(this.listScrollTimerId)
        this.listScrollTimerId = null
      }
    },
    _mousedownUp() {
      this._scrollList(-1)
    },
    _mousedownDown() {
      this._scrollList(1)
    },
    getMenu() {
      this.$api.get('/v1/webconsole/pacs/getDicomContext').then((res) => {
        if (res.status == 200) {
          this.menuItem = res.data.data
          for (let menu of this.menuItem) {
            this.reportFrames[menu] = []
          }
        }
      })
    },
    getFrames() {
      this.$api.get('/v1/webconsole/pacs/receiveImage').then((res) => {
        if (res.data && res.data.data) {
          this.val_list = res.data.data
          let data = res.data.data
          for (let frame of data) {
            let lesionAndOps = frame.lesionName + '-' + frame.operationName
            if (!this.reportFrames[lesionAndOps]) this.reportFrames[lesionAndOps] = []
            let imgs = this.reportFrames[lesionAndOps]
            imgs.push(frame.imageUrl)
          }
        }
      })
    },
    _frameDelete(frame, frames) {
      // console.log(frame, frames);
      var index
      this.val_list.forEach((item) => {
        if (item.imageUrl == frame) index = item.printIndex
      })
      this.$api
        .delete('/v1/webconsole/frameProcessForm/deletePACSPrintByIndex', {
          printIndex: index
        })
        .then((res) => {
          if (res.status == 200) {
            for (let i = 0; i < frames.length; i++) {
              if (frames[i] == frame) frames.splice(i, 1)
            }
            return this.$message.success('删除成功')
            // this.$forceUpdate()
          }
        })
    },
    _historyChange(dicom) {
      //点击历史影像树叶子节点事件
      this.selectedDicom = dicom
      this.selectedDicomViewer?.setDicom(dicom)
    },
    _historyReload(list) {
      //点击历史影像树最后2级节点事件
      this.dicoms = list
    }
  }
}
</script>
