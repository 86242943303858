function getPosition (event) {
    const currentTarget = event.currentTarget
    let x = event.offsetX
    let y = event.offsetY
    let obj = event.target
    while (obj !== currentTarget && obj !== document.body) {
        x += obj.offsetLeft
        y += obj.offsetTop
        obj = obj.offsetParent
    }
    return [x, y]
}

export {
    getPosition
}