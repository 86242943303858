<style scoped lang="less">
.dicom-item {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background: #242424;
  height: 150px;
  // border-bottom: 1px solid white;
  border-radius: 7px;
}

.dicom-item img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 7px;
}
.dicom-item .el-progress {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.progress {
  position: absolute;
  left: 80%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>

<template>
  <div class="dicom-item" @click="_click">
    <!-- <img :src="thumbnail" alt="缩略图" /> -->
    <img
      :src="baseUrl + '/api/v1/webconsole/downloadcenter/download' + series.thumbnailPath"
      alt="缩略图"
    />
    <!-- <el-progress
      :width="50"
      class="progress"
      :percentage="percentComplete"
      :show-text="false"
      type="circle"
      v-show="percentComplete < 100"
    ></el-progress> -->
  </div>
</template>

<script>
import { createImage } from '../../../../assets/js/imageUtils'
import LoginVue from '../../../Login/Login.vue'

export default {
  name: 'DicomItem',
  components: {},
  props: {
    // dicom: {
    //   type: [Object, File],
    //   require: true
    // },
    thumbnailPath: {
      type: String,
      default: ''
    },
    series: {
      type: [Object, File],
      require: true
    }
  },
  data() {
    return {
      thumbnail: '',
      percentComplete: 0,
      isLoaded: false,
      baseUrl: window.location.origin
    }
  },
  computed: {
    seriesSelected() {
      return this.series.selected
    }
  },
  watch: {
    // series: {
    //   handler(val) {
    //     ;
    //     // this.loadAndParseDicom()
    //   }
    // },
    seriesSelected: {
      handler(val) {
        if (val) {
          this.queryPacsFile()
        }
      }
    },
    dicom: {
      handler(newVal) {
        // console.log(newVal);
        let cornerstone = this.$cornerstone
        let cornerstoneWADOImageLoader = this.$cornerstoneWADOImageLoader
        cornerstone.events.addEventListener('cornerstoneimageloadprogress', function (event) {
          const eventData = event.detail
          if (eventData.url === self.dicom.filePath)
            self.percentComplete = eventData.percentComplete
        })
        let self = this
        newVal.uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        })
        //本地文件
        if (newVal instanceof File) {
          let file = newVal
          const fileId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file)
          cornerstone.loadAndCacheImage(fileId).then(
            function (image) {
              let dataSet = image.data
              // dataset is now loaded, get the # of frames so we can build the array of imageIds
              var imageIds = []
              var imageIdRoot = fileId

              var numFrames = dataSet.intString('x00280008')
              if (numFrames) {
                for (var i = 0; i < numFrames; i++) {
                  var imageId = imageIdRoot + '?frame=' + i
                  imageIds.push(imageId)
                }
              } else {
                imageIds.push(imageIdRoot)
              }
              self.dicom.studyDesc = dataSet.text('x00081030')
              self.dicom.seriesNumber = dataSet.intString('x00200011')
              self.dicom.imageIds = imageIds
              self.dicom.numFrames = numFrames
              self.thumbnail = createImage(image)
              self.isLoaded = true
            },
            function (err) {
              alert(err)
            }
          )
        }
        //服务器文件
        else {
          let url = newVal.filePath
          try {
            cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
              .load(url, cornerstoneWADOImageLoader.internal.xhrRequest)
              .then(function (dataSet) {
                // dataset is now loaded, get the # of frames so we can build the array of imageIds
                var imageIds = []
                var imageIdRoot = 'wadouri:' + url
                var numFrames = dataSet.intString('x00280008')
                if (numFrames) {
                  for (var i = 0; i < numFrames; i++) {
                    var imageId = imageIdRoot + '?frame=' + i
                    imageIds.push(imageId)
                  }
                } else {
                  imageIds.push(imageIdRoot)
                }

                // 如果像素间距不存在 如DX、XA、VL等dicom文件，则用(0018,1164)来作为像素间距，
                // 但测量转换时会存在5%-10%的误差，需要适当提醒用户
                let ImagerPixelSpacing = dataSet.floatString('x00181164')
                if (ImagerPixelSpacing) {
                  const metaDataProvider = function (type, imageId) {
                    if (type === 'imagePlaneModule' && imageIds.includes(imageId)) {
                      return {
                        columnCosines: null,
                        columnPixelSpacing: ImagerPixelSpacing,
                        columns: 512,
                        frameOfReferenceUID: undefined,
                        imageOrientationPatient: undefined,
                        imagePositionPatient: undefined,
                        pixelSpacing: null,
                        rowCosines: null,
                        rowPixelSpacing: ImagerPixelSpacing,
                        rows: 512,
                        sliceLocation: undefined,
                        sliceThickness: undefined,
                        instanceNumber: dataSet.intString('x00200013')
                      }
                    }
                  }
                  cornerstone.metaData.addProvider(metaDataProvider)
                }
                self.dicom.studyDesc = dataSet.text('x00081030')
                self.dicom.seriesNumber = dataSet.intString('x00200011')
                self.dicom.imageIds = imageIds
                self.dicom.numFrames = numFrames
                var flag = true
                self.dicom.flag = flag
                // Load and cache the first image frame.  Each imageId cached by cornerstone increments
                // the reference count to make sure memory is cleaned up properly.
                cornerstone.loadAndCacheImage(imageIds[0]).then(
                  function (image) {
                    // now that we have an image frame in the cornerstone cache, we can decrement
                    // the reference count added by load() above when we loaded the metadata.  This way
                    // cornerstone will free all memory once all imageId's are removed from the cache
                    // cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(url)
                    self.thumbnail = createImage(image)
                    self.isLoaded = true
                  },
                  function (err) {
                    alert(err)
                  }
                )
              })
          } catch (err) {
            alert('DicomItem:' + err)
          }
        }
      }
      // immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    _click() {
      // console.log(this.series)
      if (this.series) {
        this.queryPacsFile()
      }
      this.$emit('click', this.series)
    },
    // 获取 dicom 文件下载地址
    queryPacsFile() {
      // console.log(this.series)
      // parse dicom
      this.loadAndParseDicom()
    },
    // 解析 dicom 文件
    loadAndParseDicom() {
      // 解析过了已缓存，无需再解析
      // 如果是解析本地文件，就无需判断，直接解析
      // if ((!this.series.sourcePath || this.series.isLoaded) && !this.series instanceof File ) {
      //   return
      // }

      if (this.series.isLoaded) {
        return
      }
      // const promiseList = this.series.filePath.map((url) => {
      //   return this.$api.post(`/v1/webconsole/downloadcenter/download${url}`).then(res => {
      //     return res
      //   })
      // })

      // Promise.all(promiseList).then(Res => {
      //   console.log(Res);
      // })
      // return
      let self = this
      let cornerstone = this.$cornerstone
      let cornerstoneWADOImageLoader = this.$cornerstoneWADOImageLoader
      // 监听文件下载进度
      // cornerstone.events.addEventListener('cornerstoneimageloadprogress', function (event) {
      //   const eventData = event.detail
      //   console.log(eventData);
      //   self.percentComplete = eventData.percentComplete
      // })
      // console.log('this.series.filePath:', this.series.filePath);

      try {
        // 本地
        if (this.series instanceof File) {
          let file = this.series
          const fileId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file)
          cornerstone.loadAndCacheImage(fileId).then(
            function (image) {
              let dataSet = image.data
              // dataset is now loaded, get the # of frames so we can build the array of imageIds
              var imageIds = []
              var imageIdRoot = fileId

              var numFrames = dataSet.intString('x00280008')
              if (numFrames) {
                for (var i = 0; i < numFrames; i++) {
                  var imageId = imageIdRoot + '?frame=' + i
                  imageIds.push(imageId)
                }
              } else {
                imageIds.push(imageIdRoot)
              }

              let ImagerPixelSpacing = dataSet.floatString('x00181164')
              if (ImagerPixelSpacing) {
                const metaDataProvider = function (type, imageId) {
                  if (type === 'imagePlaneModule' && imageIds.includes(imageId)) {
                    return {
                      columnCosines: null,
                      columnPixelSpacing: ImagerPixelSpacing,
                      columns: 512,
                      frameOfReferenceUID: undefined,
                      imageOrientationPatient: undefined,
                      imagePositionPatient: undefined,
                      pixelSpacing: null,
                      rowCosines: null,
                      rowPixelSpacing: ImagerPixelSpacing,
                      rows: 512,
                      sliceLocation: undefined,
                      sliceThickness: undefined
                      // instanceNumber: dataSet.intString('x00200013')
                    }
                  }
                }
                cornerstone.metaData.addProvider(metaDataProvider)
              }

              self.series.seriesInstanceUid = dataSet.text('x0020000e')

              self.series.imageIds = imageIds
              var flag = true
              self.series.flag = flag

              // self.thumbnail = createImage(image)
              // console.log(self.thumbnail);
              self.isLoaded = true
              self.series.isLoaded = true

              self.$bus.$emit('dicomLoaded', self.series)
            },
            function (err) {
              alert(err)
            }
          )
          return
        }
        this.loadAllDicomDataSets(this.series.filePath)

        // let path =
        //   this.baseUrl + `/api/v1/webconsole/downloadcenter/download${this.series.filePath[0]}`
        // console.log('path:', path, this.series)
        // // 服务器
        // cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
        //   .load(path, cornerstoneWADOImageLoader.internal.xhrRequest)
        //   .then(function (dataSet) {
        //     // dataset is now loaded, get the # of frames so we can build the array of imageIds
        //     var imageIds = []
        //     var imageIdRoot = 'wadouri:' + path

        //     var numFrames = dataSet.intString('x00280008')
        //     console.log(numFrames, '1919')
        //     if (numFrames) {
        //       for (var i = 0; i < numFrames; i++) {
        //         var imageId = imageIdRoot + '?frame=' + i
        //         imageIds.push(imageId)
        //       }
        //     } else {
        //       imageIds.push(imageIdRoot)
        //     }
        //     console.log(imageIds, 'imageIds')

        //     let ImagerPixelSpacing = dataSet.floatString('x00181164')
        //     if (ImagerPixelSpacing) {
        //       const metaDataProvider = function (type, imageId) {
        //         if (type === 'imagePlaneModule' && imageIds.includes(imageId)) {
        //           return {
        //             columnCosines: null,
        //             columnPixelSpacing: ImagerPixelSpacing,
        //             columns: 512,
        //             frameOfReferenceUID: undefined,
        //             imageOrientationPatient: undefined,
        //             imagePositionPatient: undefined,
        //             pixelSpacing: null,
        //             rowCosines: null,
        //             rowPixelSpacing: ImagerPixelSpacing,
        //             rows: 512,
        //             sliceLocation: undefined,
        //             sliceThickness: undefined
        //             // instanceNumber: dataSet.intString('x00200013')
        //           }
        //         }
        //       }
        //       cornerstone.metaData.addProvider(metaDataProvider)
        //     }

        //     self.series.imageIds = imageIds
        //     self.series.flag = true
        //     // Load and cache the first image frame.  Each imageId cached by cornerstone increments
        //     // the reference count to make sure memory is cleaned up properly.
        //     cornerstone.loadAndCacheImage(imageIds[0]).then(
        //       function (image) {
        //         // now that we have an image frame in the cornerstone cache, we can decrement
        //         // the reference count added by load() above when we loaded the metadata.  This way
        //         // cornerstone will free all memory once all imageId's are removed from the cache
        //         // cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.unload(url)
        //         self.thumbnail = createImage(image)
        //         self.series.isLoaded = true
        //         self.$bus.$emit('dicomLoaded', self.series.seriesInstanceUid)
        //       },
        //       function (err) {
        //         alert(err)
        //       }
        //     )
        //   })
      } catch (err) {
        alert('dicomItem:', err)
      }
    },

    loadDicomDataSet(path) {
      let cornerstoneWADOImageLoader = this.$cornerstoneWADOImageLoader
      let cornerstone = this.$cornerstone
      return cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
        .load(path, cornerstoneWADOImageLoader.internal.xhrRequest)
        .then((dataSet) => {
          let imageIds = []
          const imageIdRoot = 'wadouri:' + path

          const numFrames = dataSet.intString('x00280008')
          if (numFrames) {
            for (let i = 0; i < numFrames; i++) {
              const imageId = imageIdRoot + '?frame=' + i
              imageIds.push(imageId)
            }
          } else {
            imageIds.push(imageIdRoot)
          }

          const ImagerPixelSpacing = dataSet.floatString('x00181164')
          if (ImagerPixelSpacing) {
            const metaDataProvider = function (type, imageId) {
              if (type === 'imagePlaneModule' && imageIds.includes(imageId)) {
                return {
                  columnCosines: null,
                  columnPixelSpacing: ImagerPixelSpacing,
                  columns: 512,
                  frameOfReferenceUID: undefined,
                  imageOrientationPatient: undefined,
                  imagePositionPatient: undefined,
                  pixelSpacing: null,
                  rowCosines: null,
                  rowPixelSpacing: ImagerPixelSpacing,
                  rows: 512,
                  sliceLocation: undefined,
                  sliceThickness: undefined
                }
              }
            }
            cornerstone.metaData.addProvider(metaDataProvider)
          }

          return { imageIds, path }
        })
        .then(({ imageIds, path }) => {
          return cornerstone.loadAndCacheImage(imageIds[0]).then((image) => {
            return { image, imageIds, path }
          })
        })
    },

    loadAllDicomDataSets(urls) {
      const promises = urls.map((url) =>
        this.loadDicomDataSet(this.baseUrl + `/api/v1/webconsole/downloadcenter/download${url}`)
      )
      setTimeout(() => {
      }, 10000)
      Promise.all(promises)
        .then((results) => {
          let self = this
          let newList = []
          results.forEach((el) => {
            newList.push(...el.imageIds)
          })
          self.series.flag = true
          self.series.imageIds = newList
          self.thumbnail = createImage(results[0].image)
          self.series.isLoaded = true
          self.$bus.$emit('dicomLoaded', self.series.seriesInstanceUid)
        })
        .catch((err) => {
          alert(err)
        })
        .finally(() => {})
    }
  }
}
</script>
