<style scoped lang="less">
.tools_bar {
  background: #242424;
  border-bottom: 1px solid white;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.tools-panel {
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 12px 5px;
  // flex: 1;

  .divide {
    height: 100%;
    background-color: #fff;
    width: 2px;
    margin: 20px;
  }
}
.panel-right {
  // width: 240px;
  position: absolute;
  right: 5px;
  display: flex;
  align-items: center;
  margin: 10px;
  justify-content: space-between;
  .el-button {
    background-color: #000;
    color: #fff;
  }
}
.tools-search {
  // float: right;
  padding: 5px;
  // height: 50px;
  // align-items: center;
  // width: 300px;
  // margin-left: 70px;

  .el-input {
    // border: 2px solid #fff;
    width: 300px;
    border-radius: 5px;
  }

  /deep/.el-input-group__append {
    background-color: #70a1ff !important;
    .el-icon-search {
      color: #fff;
    }
  }
}
.input_number {
  float: left;
  position: relative;
  color: white;
  box-sizing: border-box;
  height: 50px;
  align-items: center;
  line-height: 1;
  padding: 12px 5px;
}
// .cm_panel {
//   // display: inline-block;
//   // height: 50px;
// }

.dropdown-wrap {
  display: flex;
  // align-items: center;
}

.btn-active {
  border: 2px solid #3b90eb !important;
  box-shadow: 0 0 4px 2px #3b90eb;
}
</style>

<template>
  <div class="tools_bar">
    <div class="tools-panel" @click="toolsClick">
      <!-- <button
        class="image-button icon-openLocalFile"
        data-tool="openLocalFile"
        title="打开本地文件"
      ></button> -->
      <!--
      <button
        class="image-button icon-play"
        data-tool="play"
        title="播放"
        :class="{ 'image-button-on': this.playFlag & 1 }"
      ></button>
      <button
        class="image-button icon-pause"
        data-tool="pause"
        title="暂停"
        :class="{ 'image-button-on': this.playFlag & (1 << 1) }"
      ></button>
      <button
        class="image-button icon-reverse"
        data-tool="reverse"
        title="逆序播放"
        :class="{ 'image-button-on': this.playFlag & (1 << 3) }"
      ></button>
      <button
        class="image-button icon-stop"
        data-tool="stop"
        title="停止播放"
        :class="{ 'image-button-on': this.playFlag & (1 << 2) }"
      ></button>
      <button
        class="image-button icon-first"
        data-tool="first"
        title="跳到第一帧"
      ></button>
      <button
        class="image-button icon-pre"
        data-tool="pre"
        title="上一帧"
      ></button>
      <button
        class="image-button icon-next"
        data-tool="next"
        title="下一帧"
      ></button>
      <button
        class="image-button icon-last"
        data-tool="last"
        title="跳到最后一帧"
      ></button>
      <button
        class="image-button icon-upward"
        data-tool="upward"
        title="切换到上一张影像"
      ></button>
      <button
        class="image-button icon-down"
        data-tool="down"
        title="切换到下一张影像"
      ></button> -->
      <button
        :class="activeBtn === 1 ? 'btn-active' : ''"
        class="image-button icon-magnify"
        data-tool="magnify"
        title="放大"
      ></button>
      <button
        :class="activeBtn === 2 ? 'btn-active' : ''"
        class="image-button icon-zoom"
        data-tool="zoom"
        title="缩放"
      ></button>
      <button
        :class="activeBtn === 3 ? 'btn-active' : ''"
        class="image-button icon-pan"
        data-tool="pan"
        title="移动"
      ></button>
      <button
        :class="activeBtn === 4 ? 'btn-active' : ''"
        class="image-button icon-wwwc"
        data-tool="wwwc"
        title="亮度"
      ></button>
      <!-- for test -->
      <button
        :class="activeBtn === 5 ? 'btn-active' : ''"
        class="image-button"
        data-tool="gauge"
        title="测量"
      >
        <el-dropdown @command="gaugeSelect" trigger="click">
          <span class="el-dropdown-link" :class="gaugeList[gaugeType - 1].icon"></span>
          <el-dropdown-menu slot="dropdown">
            <div v-for="(item, index) in gaugeList" :key="index">
              <el-dropdown-item class="dropdown-wrap" :command="item">
                <i :class="item.icon_b"></i>
                {{ item.name }}
              </el-dropdown-item>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </button>
      <!-- <el-dropdown @command="saveCurrentImg" trigger="hover">
        <button
          class="image-button icon-camera el-dropdown-link"
          data-tool="camera"
          title="捕获图像"
        ></button>

        <el-dropdown-menu v-if="opMenu && opMenu.length > 0" slot="dropdown">
          <div v-for="(item, index) in opMenu" :key="index">
            <el-dropdown-item class="dropdown-wrap" :command="item">
              添加到
              <span style="color: rgb(72, 128, 192)">{{ item }}</span>
              ——数据库
            </el-dropdown-item>
          </div>
        </el-dropdown-menu>
      </el-dropdown> -->

      <!-- <button class="image-button ">
        <el-dropdown>
          <span class="el-dropdown-link icon-exportFile "> </span>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              data-tool="exportImage"
              v-debounce="toolsClick"
            >导出图片</el-dropdown-item>
            <el-dropdown-item
              data-tool="exportDICOM"
              v-debounce="toolsClick"
            >导出DICOM</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </button> -->
      <!-- <button
        class="image-button icon-exportFile"
        data-tool="exportDICOM"
        title="导出DICOM"
      ></button> -->

      <!-- <div class="split-line">······</div> -->
      <cm-panel-button
        class="cm_panel"
        buttonIconClass="icon-layout"
        :childrenButton="layoutChilds"
        v-model="layout"
        title="布局"
      ></cm-panel-button>

      <button class="image-button icon-reset" data-tool="reset" title="复位"></button>
      <!-- <div class="divide"></div> -->

      <!-- <button
          class="image-button icon-camera"
          data-tool="camera"
          title="捕获图像"
      ></button>
      <button
          class="image-button icon-exportFile"
          data-tool="addToReport"
          title="添加到手术报告"
      ></button> -->
    </div>
    <div class="panel-right">
      <el-button
        data-tool="camera"
        icon="el-icon-download"
        @click="reportClick('download')"
        title="导出MP4"
      >
        导出MP4
      </el-button>
      <el-button
        data-tool="camera"
        icon="el-icon-camera"
        @click="reportClick('camera')"
        title="保存测量记录"
      >
        保存测量记录
      </el-button>
      <el-button
        v-if="this.$route.query.operationId"
        data-tool="addToReport"
        icon="el-icon-document-add"
        @click="reportClick('addToReport')"
        title="添加至手术报告"
      >
        添加至手术报告
      </el-button>
      <el-button
        data-tool="target"
        icon="el-icon-tickets"
        @click="reportClick('target')"
        title="测量指标记录"
      >
        测量指标记录
      </el-button>
      <el-button
        v-if="this.$route.query.operationId"
        icon="el-icon-picture-outline"
        @click="reportClick('img-report')"
        data-tool="img-report"
        title="报告影像"
      >
        报告影像
      </el-button>
    </div>

    <!-- <div class="tools-search">
      <el-input placeholder="请输入标注内容" size="small" v-model="searchInfo" class="input-with-select">
        <el-button slot="append" icon="el-icon-search" style="width: 70px;" ></el-button>
      </el-input>
    </div> -->

    <!-- <div class="input_number">
      帧速率：
      <el-input-number
        v-model="num"
        @change="handleChange"
        :min="1"
        :max="50"
        size="mini"
      >
      </el-input-number>
      帧/秒
    </div>
    <div
      class="tools-panel"
      @click="toolsClick"
    >
      <button
        class="image-button icon-loop"
        data-tool="loop"
        title="区间循环播放"
      ></button>
    </div>
    <div class="input_number">
      开始帧：
      <el-input-number
        v-model="start_num"
        @change="handleChange_start"
        :min="1"
        :max="max"
        size="mini"
      >
      </el-input-number>
      帧
    </div>
    <div class="input_number">
      结束帧：
      <el-input-number
        v-model="finish_num"
        @change="handleChange_finish"
        :min="start_num"
        :max="max"
        size="mini"
      >
      </el-input-number>
      帧
    </div> -->
  </div>
</template>

<script>
import CmPanelButton from '../../../../components/CmPanelButton.vue'
import { PACSPrint } from '../../../../assets/js/SelfEntity'
import { createNewImage } from '../../../../assets/js/imageUtils'
export default {
  name: 'TopToolsBar',
  components: {
    CmPanelButton
  },
  props: {
    selectedDicomViewer: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      playFlag: 0,
      PLAY_CLEAR: 0,
      layoutChilds: [
        {
          dataTool: 'layout-1x1',
          buttonIconClass: 'icon-layout11'
        },
        {
          dataTool: 'layout-1x2',
          buttonIconClass: 'icon-layout12'
        },
        {
          dataTool: 'layout-2x1',
          buttonIconClass: 'icon-layout21'
        },
        {
          dataTool: 'layout-2x2',
          buttonIconClass: 'icon-layout22'
        },
        {
          dataTool: 'layout-1x3',
          buttonIconClass: 'icon-layout13'
        },
        {
          dataTool: 'layout-2x3',
          buttonIconClass: 'icon-layout23'
        },
        {
          dataTool: 'layout-3x3',
          buttonIconClass: 'icon-layout33'
        },
        {
          dataTool: 'layout-3x1',
          buttonIconClass: 'icon-layout31'
        },
        {
          dataTool: 'layout-3x2',
          buttonIconClass: 'icon-layout32'
        },
        {
          dataTool: 'layout-1x4',
          buttonIconClass: 'icon-layout14'
        },
        {
          dataTool: 'layout-2x4',
          buttonIconClass: 'icon-layout24'
        },
        {
          dataTool: 'layout-2x5',
          buttonIconClass: 'icon-layout25'
        },
        {
          dataTool: 'layout-5x2',
          buttonIconClass: 'icon-layout52'
        }
      ],
      layout: '',
      num: 10,
      start_num: 1,
      finish_num: 1,
      // 1:length 2:angle 3:circle
      gaugeType: 1,
      gaugeList: [
        {
          label: 'Length',
          name: '长度',
          icon_b: 'icon-gauge1',
          icon: 'icon-gauge',
          gaugeType: 1
        },
        {
          label: 'Angle',
          name: '角度',
          icon_b: 'icon-angle1',
          icon: 'icon-angle',
          gaugeType: 2
        },
        {
          label: 'EllipticalRoi',
          name: '椭圆',
          icon_b: 'icon-circle1',
          icon: 'icon-circle',
          gaugeType: 3
        },
        {
          label: 'FreehandRoi',
          name: '多边形',
          icon_b: 'icon-freehand1',
          icon: 'icon-freehand',
          gaugeType: 4
        },
        {
          label: 'RectangleRoi',
          name: '矩形',
          icon_b: 'icon-rectangle1',
          icon: 'icon-rectangle',
          gaugeType: 5
        },
        {
          label: 'TextMarker',
          name: '文字标记',
          icon_b: 'icon-text1',
          icon: 'icon-text',
          gaugeType: 6
        }
        // {
        //   label: 'ArrowAnnotate',
        //   icon_b: 'icon-annotation1',
        //   icon: 'icon-annotation',
        //   gaugeType: 5
        // }
      ],
      tools: [
        {
          name: 'Magnify',
          label: '放大',
          props: {}
        },
        {
          name: 'Zoom',
          label: '缩放',
          props: {
            configuration: {
              invert: false,
              preventZoomOutsideImage: false,
              minScale: 0.1,
              maxScale: 20.0
            }
          }
        },
        {
          name: 'Pan',
          label: '移动',
          props: {}
        },
        {
          name: 'Wwwc',
          label: '亮度',
          props: {}
        },
        {
          name: 'Angle',
          label: '角度',
          props: {
            configuration: {
              drawHandlesOnHover: true, //是否显示圆点
              hideHandlesIfMoving: false, //是否显示圆点移动轨迹
              renderDashed: false //是否虚线渲染
            }
          }
        },
        {
          name: 'Length',
          label: '长度',
          props: {
            configuration: {
              drawHandlesOnHover: true, //是否显示圆点
              hideHandlesIfMoving: false, //是否显示圆点移动轨迹
              renderDashed: false //是否虚线渲染
            }
          }
        },
        {
          name: 'EllipticalRoi',
          label: '椭圆',
          props: {
            configuration: {
              showMinMax: false,
              showHounsfieldUnits: true,
              alwaysShowHandles: false
            }
          }
        },
        {
          name: 'RectangleRoi',
          label: '矩形',
          props: {
            configuration: {
              showMinMax: false,
              showPerimeter: true,
              showHounsfieldUnits: true,
              alwaysShowHandles: false
            }
          }
        },
        {
          name: 'TextMarker',
          label: '文字标记',
          props: {
            configuration: {
              markers: ['F5', 'F4', 'F3', 'F2', 'F1'],
              current: 'F5',
              ascending: true,
              loop: true
            }
          }
        },
        {
          name: 'FreehandRoi',
          label: '多边形',
          props: {
            configuration: {
              // 控制柄的间隔
              spacing: 1,
              // 控制柄的半径
              activeHandleRadius: 3,
              // 闭环时的控制柄半径，指的是首尾相接的控制柄半径
              completeHandleRadius: 6
            }
          }
        }
        // {
        //   name: 'ArrowAnnotate',
        //   label: '箭头标注',
        //   props: {
        //     configuration: {
        //       drawHandlesOnHover: true, //是否显示圆点
        //       hideHandlesIfMoving: false, //是否显示圆点移动轨迹
        //       renderDashed: false //是否虚线渲染
        //     }
        //   }
        // }
      ],
      searchInfo: '',
      activeBtn: undefined
    }
  },
  computed: {
    max: function () {
      return this.$store.state.frame_num
    },
    opMenu: function () {
      return this.selectedDicomViewer?.menuItem
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.toolInit()
    this.$bus.$on('measurementClick', this.updateGaugeIcon)
    this.$bus.$on('dicomViewerChanged', () => {
      this.activeBtn = undefined
    })
  },
  beforeDestroy() {
    this.$bus.$off('measurementClick')
    this.$bus.$off('dicomViewerChanged')
  },
  methods: {
    saveCurrentImg(val) {
      // ;
      let pacsPrint = new PACSPrint()
      pacsPrint.lesionName = val
      const canvas = this.selectedDicomViewer.$refs.DicomImage.querySelector('canvas')
      const image = this.selectedDicomViewer.image
      const element = this.selectedDicomViewer.$refs.DicomImage
      // pacsPrint.base64Data = createImage(this.image)
      pacsPrint.base64Data = createNewImage(canvas, image, element)
      console.log(canvas, image, element)
      // this.$api.post('/v1/webconsole/pacs/receiveImage', pacsPrint).then(({ data }) => {
      //   // console.log(data);
      //   if (data.status === 200) this.$message.success(data.msg)
      // })
    },
    updateGaugeIcon(msg) {
      // console.log('bar', msg);
      let val = msg.data
      const toolName = val.toolName
      if (toolName) {
        this.activeBtn = 5
      }
      const tool = this.gaugeList.find((item) => item.label === toolName)
      this.gaugeType = tool.gaugeType
    },
    // 监听测量事件
    handleLengthTool(event) {
      console.log(event)
    },
    // 工具初始化
    toolInit() {
      this.tools.forEach((tool) => {
        this.addTool(tool.name, tool.props)
      })
    },
    addTool(toolName, props) {
      const cornerstoneTools = this.$cornerstoneTools

      const ApiTool = cornerstoneTools[`${toolName}Tool`]

      cornerstoneTools.addTool(ApiTool, props)
    },
    // 测量器选择
    gaugeSelect(item) {
      // console.log(item);
      this.activeBtn = 5
      this.gaugeType = item.gaugeType
      const cornerstoneTools = this.$cornerstoneTools
      cornerstoneTools.setToolActive(item.label, { mouseButtonMask: 1 })
    },
    reportClick(type) {
      this.$emit('click', type)
    },
    toolsClick(e) {
      const tool = e.target.dataset.tool
      console.log(tool)
      if (tool === 'play') {
        this.playFlag &= this.PLAY_CLEAR
        this.playFlag |= 1
      } else if (tool === 'pause') {
        this.playFlag &= this.PLAY_CLEAR
        this.playFlag |= 1 << 1
      } else if (tool === 'stop') {
        this.playFlag &= this.PLAY_CLEAR
        this.playFlag |= 1 << 2
      } else if (tool === 'reverse') {
        this.playFlag &= this.PLAY_CLEAR
        this.playFlag |= 1 << 3
      }
      let cornerstoneTools = this.$cornerstoneTools
      if (tool === 'magnify') {
        this.activeBtn = 1
        cornerstoneTools.setToolActive('Magnify', {
          mouseButtonMask: 1
        })
      } else if (tool === 'zoom') {
        this.activeBtn = 2
        cornerstoneTools.setToolActive('Zoom', {
          mouseButtonMask: 1
        })
      } else if (tool === 'pan') {
        this.activeBtn = 3
        cornerstoneTools.setToolActive('Pan', {
          mouseButtonMask: 1
        })
      } else if (tool === 'wwwc') {
        this.activeBtn = 4
        cornerstoneTools.setToolActive('Wwwc', {
          mouseButtonMask: 1
        })
      } else if (tool === 'reset') {
        this.activeBtn = undefined
        let cornerstone = this.$cornerstone
        // console.log('复位');
        // console.log('selectedDicomViewer:', this.selectedDicomViewer);
        // console.log('cornerstone:', this.$cornerstone);
        // console.log('cornerstoneTools:', this.$cornerstoneTools);
        // 失活测量
        let toolList = [
          'EllipticalRoi',
          'Length',
          'Angle',
          'FreehandRoi',
          'RectangleRoi',
          'TextMarker'
        ]
        let cornerstoneTools = this.$cornerstoneTools
        toolList.forEach((item) => {
          let flag = cornerstoneTools.isToolActiveForElement(
            this.selectedDicomViewer.elements[0],
            item
          )
          if (flag) {
            // console.log(`${item}激活的, setPassive`);
            cornerstoneTools.setToolEnabled(item)
          }
        })
        // 重制视口
        cornerstone.reset(this.selectedDicomViewer.elements[0])
      } else if (tool === 'camera') {
        // let element = this.selectedDicomViewer.$refs.DicomImage
        // let canvas = this.selectedDicomViewer.$refs.DicomImage.querySelector('canvas')
        // let image = this.selectedDicomViewer.image
        // downloadImage(image, '123', canvas, element)
      }
      this.$emit('click', tool)
    },
    refreshStatus() {
      this.playFlag &= this.PLAY_CLEAR
    },
    handleChange() {
      this.$store.state.num = this.num
    },
    handleChange_start() {
      this.$store.state.start_num = this.start_num
    },
    handleChange_finish() {
      this.$store.state.finish_num = this.finish_num
    }
  }
}
</script>
