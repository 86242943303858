<style scoped lang="less">
.measure-wrap {
  font-size: 15px;
  padding: 3px;

  .img-measured {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .image {
      position: relative;
      .el-image {
        height: 180px;
        width: 180px;
      }
      .delete-img {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 50%;
        cursor: pointer;
        img {
          border-radius: 50%;
          // box-shadow: 0 0 15px 3px #000;
        }
      }
    }
    .upload {
      cursor: pointer;

      img {
        height: 35px;
        width: 35px;
      }
    }
  }
  .measure-annotation {
    margin: 3px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .annotation-wrap {
      max-width: 200px;
      word-wrap: break-word;
      white-space: normal;
      // color: #fdcb6e;
    }
  }
  .measure-info {
    border-left: 2px solid #409eff;
    padding: 2px 10px;
    word-wrap: break-word;
    .info-item {
      // text-align: center;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

.el-table {
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 13px; /*滚动条宽度*/
    height: 10px; /*滚动条高度*/
  }
  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: rgba(0, 0, 0, alpha); /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    // box-shadow: 0px 1px 3px rgba(229, 227, 227, 0.749) inset; /*滚动条的内阴影*/
    // border-radius: 10px; /*滚动条的圆角*/
    // background-color: rgba(237, 232, 232, 0.765)/*滚动条的背景颜色*/
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
  }
  /deep/ tbody tr:hover > td {
    background-color: rgba(75, 101, 132, 1) !important;
  }
}
</style>

<template>
  <el-table
    :data="imgs"
    width="100%"
    class="custom-table1"
    :fit="true"
    height="100%"
    ref="table"
    @row-click="(row, column) => select(row, column)"
    @cell-mouse-enter="(row, column, cell) => (this.hoverRowUuid = row.id)"
    @cell-mouse-leave="(row) => (this.hoverRowUuid = undefined)"
  >
    <el-table-column width="30px" type="index"></el-table-column>
    <el-table-column label="报告影像">
      <template slot-scope="scope">
        <div class="measure-wrap">
          <div class="img-measured">
            <div class="image">
              <el-image
                style="width: 160px; height: 160px"
                :src="baseUrl + `/api/v1/webconsole/downloadcenter/download` + scope.row.imageUrl"
                alt=""
                :preview-src-list="[
                  baseUrl + '/api/v1/webconsole/downloadcenter/download' + scope.row.imageUrl
                ]"
              ></el-image>
              <div class="delete-img" @click="deleteImgFromReport(scope.row)">
                <img src="../../../../assets/img/delete.png" alt="" />
              </div>
            </div>
          </div>
          <div class="measure-info">
            <div class="info-item" v-show="scope.row.examineName">
              <span>{{ scope.row.examineName }} | {{ scope.row.lesionName }}</span>
            </div>
            <!-- <div class="info-item"> <span>病变部位: {{ scope.row.lesionName }}</span></div> -->
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'ReportImageList',
  components: {},
  props: {
    imgs: {
      type: Array,
      default: []
    }
  },
  watch: {
    imgs: {
      handler(val, oldVal) {},
      deep: true
    }
  },
  data() {
    return {
      selectRowUuid: 0,
      hoverRowUuid: undefined,
      baseUrl: window.location.origin
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    select(row, column) {
      // console.log('row', row);
      this.selectRowUuid = row.uuid
      // 先注销，还有on操作没改
      // this.$bus.$emit('measurementClick', { filePath:row.dicom.filePath, data:row })
    },
    // 删除图片
    deleteImgFromReport(row) {
      console.log(row)
      this.$api
        .post(`/v1/webconsole/pacs/report/delete/${row.id}`)
        .then(({ data }) => {
          console.log(data)
          if (data.status == 200) {
            this.$emit('deletedImgFromReport')
            this.$message.success(data.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
